import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-donut',
  templateUrl: './simple-donut.component.html',
  styleUrls: ['./simple-donut.component.scss']
})
export class SimpleDonutComponent implements OnInit {

  f1 = (pt) => { return [pt[0], '10%']; };

  @Input()
  public mergeOption: any;
  public loading = false;

  private _dynamic: any;
  @Input()
  set dynamic(dynamic: any) {
    if (dynamic.options.echartOptions.tooltip) {
      if ((dynamic.options.echartOptions.tooltip).position == "[pt[0], '10%']") {
        dynamic.options.echartOptions.tooltip.position = this.f1
      }
    }
    this._dynamic = dynamic;
  };

  public get dynamic() {
    return this._dynamic;
  };

  private _config: any;
  @Input()
  set config(config: any) {
    this._config=config;
    if (config) {
      var newSeries = [
        {
          name: this.config.title,
          type: "gauge",
          startAngle: 180,
          endAngle: 0,
          splitNumber: 5,
          itemStyle: {
            color: "5c7bd9"
          },
          progress: {
            show: true,
            width: 30
          },
          pointer: {
            show: false
          },
          axisLine: {
            lineStyle: {
              width: 30
            }
          },
          axisTick: {
            distance: -45,
            splitNumber: 5,
            lineStyle: {
              width: 2,
              color: "#999"
            }
          },
          splitLine: {
            distance: -52,
            length: 14,
            lineStyle: {
              width: 3,
              color: "#999"
            }
          },
          axisLabel: {
            distance: -15,
            color: "#999",
            fontSize: 18
          },
          anchor: {
            show: false,
            showAbove: true,
            size: 25,
            itemStyle: {
              borderWidth: 10
            }
          },
          title: {
            show: true,
            offsetCenter: [0, "15%"],
            fontSize: 30,
            color: "#999"
          },
          detail: {
            valueAnimation: true,
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, "-15%"],
            fontSize: 60,
            fontWeight: "bolder",
            formatter: "{value} %",
            color: "auto"
          },
          data: [
            { value: this.config.val, name: this.config.title }
          ]
        }
      ];
      this.loading = true;
      this.mergeOption = { series: newSeries };
      this.loading = false;
    }
  };


  get config() {
    return this._config;
  }

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    var newSeries = [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        splitNumber: 5,
        itemStyle: {
          color: "#5c7bd9"
        },
        progress: {
          show : true,
          width: 30
        },
        pointer: {
          show: false
        },
        axisLine: {
          lineStyle: {
            width: 30
          }
        },
        axisTick: {
          distance: -45,
          splitNumber: 5,
          lineStyle: {
            width: 2,
            color: "#999"
          }
        },
        splitLine: {
          distance: -52,
          length: 14,
          lineStyle: {
            width: 3,
            color: "#999"
          }
        },
        anchor: {
          show: false,
          showAbove: true,
          size: 25,
          itemStyle: {
            borderWidth: 10
          }
        },
        axisLabel: {
          distance: -15,
          color: "#999",
          fontSize: 18
        },
        title: {
          show: true,
          offsetCenter: [0, "15%"],
          fontSize: 30,
          color: "#999"
        },
        detail: {
          valueAnimation: true,
          lineHeight: 40,
          borderRadius: 8,
          offsetCenter: [0, "-15%"],
          fontSize: 60,
          fontWeight: "bolder",
          formatter: "{value} %",
          color: "auto"
        },
        data: [
          { value: this.config.val, name: this.config.title }
        ]
      }
    ];
    this.loading = true;
    this.mergeOption = { series: newSeries};
    this.loading = false;
  }

  get measTrig() {
    return this._measTrig;
  }

  public option = {
    title: "",  

    series: [{
      startAngle: 180,
      endAngle: 0,
      splitNumber: 5,
      itemStyle: {
        color: "#5c7bd9"
      },
      progress: {
        show: true,
        width: 30
      },
      pointer: {
        show: false
      },
      axisLine: {
        lineStyle: {
          width: 30
        }
      },
      axisTick: {
        distance: -45,
        splitNumber: 5,
        lineStyle: {
          width: 2,
          color: "#999"
        }
      },
      splitLine: {
        distance: -52,
        length: 14,
        lineStyle: {
          width: 3,
          color: "#999"
        }
      },
      axisLabel: {
        distance: -15,
        color: "#999",
        fontSize: 18
      },
      anchor: {
        show: false,
        showAbove: true,
        size: 25,
        itemStyle: {
          borderWidth: 10
        }
      },
      title: {
        show: true,
        offsetCenter: [0, "15%"],
        fontSize: 30,
        color: "#999"
      },
      detail: {
        valueAnimation: true,
        lineHeight: 40,
        borderRadius: 8,
        offsetCenter: [0, "-15%"],
        fontSize: 60,
        fontWeight: "bolder",
        formatter: "{value} %",
        color: "auto"
      },
      data: [

      ]
    }]
  };

  constructor() { }

  ngOnInit(): void {

  }

}
