import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { TabList } from 'src/app/models/scada-screens';
import { ClearDynamics, SetDynamics, SetSelectedTab } from '..';
import {
  SetTabList, 
  ClearTabList, ClearMetaData, SetMetaData, ClearSelectedTab, SetAlarms, ClearAlarms, SetDynLookup, ClearDynLookup
} from './scada.actions';


export class ScadaStateModel {
  dynamics: any;
  dynlookup: any;
  alarms: any;
  tabList: TabList;
  selectedTab: number;
  metaData: [{}];
}

@State<ScadaStateModel>({
  name: 'scada',
  defaults: {
    dynamics: null,
    dynlookup: null,
    alarms: null,
    tabList: null,
    selectedTab: 0,
    metaData: null,
  }
})

@Injectable()
export class ScadaState {

  @Selector()
  static getDynamics(state: ScadaStateModel) {
    return state.dynamics;
  }

  @Selector()
  static getAlarms(state: ScadaStateModel) {
    return state.alarms;
  }

  @Selector()
  static getDynLookup(state: ScadaStateModel) {
    return state.dynlookup;
  }

  @Selector()
  static getTabList(state: ScadaStateModel) {
    return state.tabList;
  }

  @Selector()
  static getMetaReadings(state: ScadaStateModel) {
    return state.metaData;
  }

  @Selector()
  static getSelectedTab(state: ScadaStateModel) {
    return state.selectedTab;
  }

  @Action(SetTabList)
  setTabList({ getState, patchState }: StateContext<ScadaStateModel>, { payload }: SetTabList) {
    const state = getState();
    patchState({
      tabList: payload
    });
  }

  @Action(ClearTabList)
  clearTabList({ getState, patchState }: StateContext<ScadaStateModel>, { payload }: ClearTabList) {
    const state = getState();
    patchState({
      tabList: null
    });
  }

  @Action(SetMetaData)
  setMetaData({ getState, patchState }: StateContext<ScadaStateModel>, { payload }: SetMetaData) {
    const state = getState();
    patchState({
      metaData: payload
    });
  }

  @Action(ClearMetaData)
  clearMetaData({ getState, patchState }: StateContext<ScadaStateModel>, { payload }: ClearMetaData) {
    const state = getState();
    patchState({
      metaData: null
    });
  }

  @Action(SetDynamics)
  setDynamics({ getState, patchState }: StateContext<ScadaStateModel>,
    { payload }: SetDynamics) {
    const state = getState();
    patchState({
      dynamics: payload
    });
  }

  @Action(ClearDynamics)
  clearDynamics({ getState, patchState }: StateContext<ScadaStateModel>) {
    const state = getState();
    patchState({
      dynamics: null
    });
  }

  @Action(SetDynLookup)
  setDynLookup({ getState, patchState }: StateContext<ScadaStateModel>,
    { payload }: SetDynLookup) {
    const state = getState();
    patchState({
      dynlookup: payload
    });
  }

  @Action(ClearDynLookup)
  clearDynLookup({ getState, patchState }: StateContext<ScadaStateModel>) {
    const state = getState();
    patchState({
      dynlookup: null
    });
  }

  @Action(SetAlarms)
  setAlarms({ getState, patchState }: StateContext<ScadaStateModel>,
    { payload }: SetAlarms) {
    const state = getState();
    patchState({
      alarms: payload
    });
  }

  @Action(ClearAlarms)
  clearAlarms({ getState, patchState }: StateContext<ScadaStateModel>) {
    const state = getState();
    patchState({
      alarms: null
    });
  }

  @Action(SetSelectedTab)
  setSelectedTab({ getState, patchState }: StateContext<ScadaStateModel>,
    { payload }: SetSelectedTab) {
    const state = getState();
    patchState({
      selectedTab: payload
    });
  }

  @Action(ClearSelectedTab)
  clearSelectedTAb({ getState, patchState }: StateContext<ScadaStateModel>) {
    const state = getState();
    patchState({
      selectedTab: 0
    });
  }

}
