import { ShapesService } from 'src/app/shared/services/shapes.service';
import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { SaiUtilsService } from 'src/app/shared/services/sai-utils.service'
import { SaiSessionState } from 'src/app/state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

interface ShapeInfo {
  outline_d: string,
  outlineFillColor: string,
  outlineStrokeWidth: number,
  outlineStrokeColor: string,
  floodFillColor: string,
  flood_d: string,
  floodStrokeWidth: number,
  floodStrokeColor: string,
  floodBGColor: string,
  statusPath_d: string,
  statusOkColor: string,
  statusOffColor: string,
  statusAlarmColor1: string,
  statusAlarmColor2: string,
  statusStrokeWidth: number,
  additional_d: string,
  additionalStrokeColor: string,
  additionalStrokeWidth: number,
  additionalFillColor: string,
  positions: any,
};

@Component({
  selector: '[app-shaped-gauge]',
  templateUrl: './shaped-gauge.component.html',
  styleUrls: ['./shaped-gauge.component.scss'],
})
export class ShapedGaugeComponent implements OnInit, OnDestroy {
  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild('floodarea', { static: true }) floodarea: ElementRef;
  @ViewChild('filt', { static: true }) myfilt: ElementRef;
  @ViewChild('offsk', { static: true }) offs: ElementRef;

  public _val: number;

  public off = false;
  public inalarm = false;
  public alarmReason: string = '';
  public metaDataVisible = false;
  public statVal: string;
  public shapeInfo: ShapeInfo;
  public valRefs: string[];
  public starting = true;
  public legendText:string;


  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;

  @Input() dynamic: any;

  @Input() metaData: any[];

  public get measTrig() {
    return this._measTrig;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    if (!this.starting) {
      this.renderer.setAttribute(
        this.offs.nativeElement,
        'dy',
        this.dynamic.variables[this.valRefs[0]].value === null 
          ? 
          '1' :
          ( 1 -
            (this.dynamic.variables[this.valRefs[0]].value - this.dynamic.range.min) /
            (this.dynamic.range.max - this.dynamic.range.min)
          ).toString()
         
      );
      
      this.inalarm=(this.dynamic.variables[this.valRefs[0]].inalarm || this.dynamic.status!='good') && !this.off;
    }
  }
  
  @Output() givenCommand = new EventEmitter<{
    ts: number;
    sourceElem: string;
    command: string;
  }>();

  giveCommand({ source, command }) {
    this.givenCommand.emit({
      ts: new Date().valueOf(),
      sourceElem: source,
      command: command,
    });
  }

  public svgTransform: string;

  onClick(): void {
    this.giveCommand({
      source: this.dynamic.ref,
      command: 'click',
    });
  }

  constructor(
    private renderer: Renderer2,
    private shapesService: ShapesService,
    public translate: TranslateService,
    private utils: SaiUtilsService,
  ) {  }


  ngOnInit(): void {
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.shapeInfo = this.shapesService.shapeInfo[this.dynamic.shape];
    this.valRefs = Object.keys(this.dynamic.variables);

    this.renderer.setAttribute(
      this.element.nativeElement,
      'id',
      this.valRefs[0]
    );
    const secfilterid = 'flood' + this.dynamic.tab + '.' + this.valRefs[0];
    this.renderer.setAttribute(this.myfilt.nativeElement, 'id', secfilterid);
    this.renderer.setAttribute(
      this.offs.nativeElement,
      'dy', '1'
    );
    this.sessionLanguageSub=this.sessionLanguage$.subscribe(lang =>{ 
      this.legendText=this.translate.instant('SCADA.' + this.dynamic.legend);
    });
    this.renderer.setAttribute(
      this.floodarea.nativeElement,
      'filter',
      'url(#' + secfilterid + ')'
    );
    this.starting = false;
  }

  ngOnDestroy(): void {
    this.sessionLanguageSub.unsubscribe();
  }
}
