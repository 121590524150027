// angular
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
// Auth service
import { AuthService } from './auth.service';
// Prime ng
import {MessageService} from 'primeng/api';
//
import {AppComponent} from '../app.component';
import { AppConfig } from '../shared/services';


@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    providers: [MessageService]
})
export class AppLoginComponent implements OnInit {

    loading = false;

    email: string;
    password: string;


    constructor(public app: AppComponent, private authService: AuthService, private router: Router,
                private messageService: MessageService,
                public appConfig: AppConfig) {
    }

    ngOnInit(): void {

    }

    async onSubmit(e) {
        e.preventDefault();
        this.loading = true;
        const result = await this.authService.logIn(this.email, this.password);
        if (!result.isOk) {
          this.loading = false;
          this.messageService.add({severity: 'error', summary: 'Authentication failed',
              detail: 'User or password is not correct'});
        } else {
          this.messageService.add({severity: 'success', summary: 'Authentication succeeded',
              detail: 'You have been successfully authenticated'});
        }
      }

}
