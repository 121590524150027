import { ClearParsedData, SetParsedAlarms, SetParsedData, SetParsedMeasurements, SetParsedMetaData, SetParsedRawData } from './../../state/live-data/live-data.actions';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { ScadaState } from 'src/app/state';
import { loadavg } from 'os';

/**
 * This service processes the measurements and alarms when updated in the store by the mqttService.
 * Parsed measurements and parsed alarms are updated in the store
 * It also defines a rxjs Subject to transfer commands from the gui to the mqttService fot publishing.
 */

@Injectable({
  providedIn: 'root',
})
export class LiveDataService {

  public commands = new Subject<{
    ts: number;
    sourceItem: string;
    sourceElem: string;
    command: string | number;
  }>();

  public dynamics = {};
  @Select(ScadaState.getDynamics) dynamics$: Observable<any>;

  public dynLookup = {};
  @Select(ScadaState.getDynLookup) dynLookup$: Observable<any>;

  public alarmDefs = {};
  @Select(ScadaState.getAlarms) alarmDefs$: Observable<any>;

  constructor(private store: Store) {
    this.load();
  }

  load() {
    this.dynamics$.subscribe((dynamics: any) => {
      if (dynamics) {
        this.dynamics = dynamics;
      } else {
        this.dynamics = {};
      }
    });
    this.dynLookup$.subscribe((dynLookup: any) => {
      if (dynLookup) {
        this.dynLookup = dynLookup;
      } else {
        this.dynLookup = {};
      }
    });
    this.alarmDefs$.subscribe((alarmDefs: any) => {
      if (alarmDefs) {
        this.alarmDefs = alarmDefs;
      } else {
        this.alarmDefs = {};
      }
    });
  }

  processAlarms(alarms: any, topicId: string) {
    var parsedAlms = {};
    if (alarms.res) {
      const alms = Object.keys(alarms.res);
      alms.forEach(alm => {
        if (alarms.res[alm].s == "on") {
          const vals = Object.keys(alarms.res[alm].ctx.vals);
          vals.forEach(val => {
            let valParts = String(alarms.res[alm].ctx.vals[val].p).split('.');
            if (valParts[1] == "s") {

            } else {
              var valId = String(alarms.res[alm].ctx.vals[val].p).split('.')[0] + '.' +
                String(alarms.res[alm].ctx.vals[val].p).split('.')[2];
              parsedAlms["meas." + valId+'.'+this.alarmDefs[ alarms.res[alm].ctx.oid].nme] = {
                'asset': valId,
                'almval': alarms.res[alm].ctx.vals[val].v,
                'reason': alarms.res[alm].ctx.reason,
                'oid': alarms.res[alm].ctx.oid,
                'id': alarms.res[alm].ctx.id,
                'alarm': this.alarmDefs[ alarms.res[alm].ctx.oid].text
              }
            }
          })
        }
      });
      this.store.dispatch(new SetParsedAlarms(parsedAlms));
    }

  }

  processMqttMessages(topic: string, message: any, topicId: string) {
    if (topicId != 'alm') {
      const devices = Object.keys(message.res);
      const data = {};
      devices.forEach(device => {
        const vs = Object.keys(message.res[device].v);
        data[topicId + "." + device] = {
          fromTopic: topic,
          status: message.res[device].s,
          vars: vs.map(v => topicId + "." + device + "." + v)
        }
        vs.forEach(v => {
          data[topicId + "." + device + "." + v] = {
            status: message.res[device].s,
            value: message.res[device].v[v]
          }
        });
      });
      this.store.dispatch(new SetParsedData(data));
      if (this.dynamics) {
        var tabs = Object.keys(this.dynamics);
        tabs.forEach(tab => {
          this.dynamics[tab].forEach(dynamic => {
            if (data[dynamic.statusSensor]) {
              dynamic.status = data[dynamic.statusSensor].status;
            }
            var vars = Object.keys(dynamic.variables);
            vars.forEach((v) => {
              if (data[v]) {
                dynamic.variables[v].value = data[v].value;
                dynamic.variables[v].status = data[v].status;
                dynamic.variables[v].lastUpdate = message.ts;
              }
            });
          });
        })
      }
    } else {
      if (message.res) {
        var parsedAlms = {};
        const alms = Object.keys(message.res);
        alms.forEach(alm => {
          if (this.alarmDefs[message.res[alm].ctx.oid]) {
            const measKeys = Object.keys(this.alarmDefs[message.res[alm].ctx.oid].measurements);
            measKeys.forEach(measKey => {
              if (this.dynLookup[measKey]) {
                this.dynLookup[measKey].forEach(dynref => {
                  this.dynamics[dynref.tab][dynref.index].variables[measKey].inalarm = (message.res[alm].s != 'off');
                  this.dynamics[dynref.tab][dynref.index].variables[measKey].alarms[this.alarmDefs[message.res[alm].ctx.oid].nme]={
                    active: message.res[alm].s != 'off',
                    almOid: message.res[alm].ctx.oid
                  }
                });
              };
            });
          };
          message.res[alm].ctx.vals.forEach(val => {
            if (val.s) {

              // parsedAlms["meas." + val] =  {
              //   'almActive': message.res[alm].s != "off",
              //   'reason': message.res[alm].ctx.reason,
              //   'oid': message.res[alm].ctx.oid,
              //   'id': message.res[alm].ctx.id              
              //}

            } else {
              var valId = String(val.p).split('.')[0] + '.' + String(val.p).split('.')[2];
              parsedAlms["meas." + valId+'.'+this.alarmDefs[message.res[alm].ctx.oid].nme] = {
                'almActive': message.res[alm].s != "off",
                'almval': val.v,
                'reason': message.res[alm].ctx.reason,
                'oid': message.res[alm].ctx.oid,
                'id': message.res[alm].ctx.id
              }
            }
          })
        });
        const dynlus = Object.keys(this.dynLookup);
        dynlus.forEach(dynlu=>{
          this.dynLookup[dynlu].forEach(dyn => {
            let vrbs=Object.keys(this.dynamics[dyn.tab][dyn.index].variables);
            
            vrbs.forEach(vrb=>{
              let vralams=Object.keys(this.dynamics[dyn.tab][dyn.index].variables[vrb].alarms);
              let inalm=false;
              vralams.forEach(vralm => {
                if (this.dynamics[dyn.tab][dyn.index].variables[vrb].alarms[vralm].active){
                  inalm=true
                }
              });
              this.dynamics[dyn.tab][dyn.index].variables[vrb].inalarm=inalm;
            })
          });
        })
        this.store.dispatch(new SetParsedAlarms(parsedAlms));
      }
    }
  }
}
