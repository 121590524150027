import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ApiRequestService } from 'src/app/shared/services';
import { LiveDataState } from 'src/app/state/live-data/live-data.state';
import  { AssetTreeState } from 'src/app/state';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import {CalendarModule} from 'primeng/calendar';
import { TreeNode } from 'primeng/api';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  @Select(LiveDataState.getParsedData) parsedData$: Observable<any[]>;
  private parsedDataSub: Subscription;

  @Select(AssetTreeState.getSelectedAsset) selectedAsset$: Observable<TreeNode>;
  private selectedAssetSub: Subscription;

  private device:string;

  public measTrigger: number = 0;
  public chartAttributesList: any[] = [];

  date: Date;

  f1 = (pt) => { return [pt[0], '10%']; };

  private _tab: any;
  @Input()
  set tab(tab: any) {
    this._tab = tab;
  };

  public get tab() {
    return this._tab;
  };

  private _dynamics: any;
  @Input()
  set dynamics(dynamics: any) {
    this._dynamics = dynamics;
  };

  public get dynamics() {
    return this._dynamics;
  };

  last24(e) {
    this.date= new Date();
    this.date.setDate(this.date.getDate()-1);
  }

  constructor(private apiRequest: ApiRequestService) { }

  ngOnDestroy(): void {
    if (this.parsedDataSub) {
      this.parsedDataSub.unsubscribe()
    };
    if (this.selectedAssetSub) {
      this.selectedAssetSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    //this.getData();
    this.date=new Date();
    this.date.setDate(this.date.getDate()-1); //for prod
    //this.date.setHours(this.date.getHours()-1); //for dav
    this.parsedDataSub = this.parsedData$.subscribe((parsedData: any[]) => {
      if (parsedData) {
        this.measTrigger < 3 ? this.measTrigger++ : this.measTrigger = 1;
      };
    });
    this.selectedAssetSub = this.selectedAsset$.subscribe(treeNode => {
       this.device=treeNode.data?.device;
    })  

  }

  getData() {
    this.chartAttributesList.forEach(chartAtributes => {
      chartAtributes.loading = true
    });
    this.apiRequest.getHistory('sec-0028-0042', '20m').then((res: Array<any>) => {
      if (res.length > 0) {
        var data: any = prepEchartDataSet(<Array<any>>res);
        this.chartAttributesList.forEach(chartAtributes => {
          chartAtributes.options.dataset.source = data.source;
          chartAtributes.options.dataset.dimensions = data.dimensions;
          chartAtributes.mergeOption = { dataset: data }
          chartAtributes.loading = false;
        });
      }
    });
  }

  update() {
    console.log('updating...');
  }
}

function prepEchartDataSet(data: any[]) {
  var datasetObj = {};
  var dataSet = [];
  var tsen = [];
  var dimensions = ['timestamp'];
  <Array<any>>data[0].v.forEach(pp => {
    tsen.push(pp.ts);
    datasetObj[pp.ts] = [];
    datasetObj[pp.ts].push(pp.ts);
  })
  data.forEach(dat => {
    dimensions.push(dat.s + '.' + dat.m);
    <Array<any>>dat.v.forEach(tt => {
      datasetObj[tt.ts].push(tt.v);
    })
  });
  <Array<any>>data[0].v.forEach(zz => {
    dataSet.push(datasetObj[zz.ts]);
  })
  return {
    dimensions: dimensions,
    source: dataSet
  }
}
