<svg #door>
  <g class="bg-sv" [attr.transform]="svgTransform">
    <path [ngClass]="{ok : (dynamic.variables[valRefs[0]].value==1 && !dynamic.variables[valRefs[2]].inalarm),
      alarm : (dynamic.variables[valRefs[0]].value==1 && dynamic.variables[valRefs[2]].inalarm),
                      hidden : (dynamic.variables[valRefs[0]].value==0)}"
      [attr.stroke-width]="shapesService.doors[dynamic.shape].strokeWidth" fill="none"
      [attr.d]="shapesService.doors[dynamic.shape].open_d">
      <animateTransform [attr.id]="dynamic.id+'open'"
                           attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          [attr.from]="shapesService.doors[dynamic.shape].openfrom"
                          [attr.to]="shapesService.doors[dynamic.shape].opento"
                          dur="1s"
                          repeatCount="1"/>
    </path>
    <path [ngClass]="{ok : (dynamic.variables[valRefs[0]].value==0),
                                 hidden : (dynamic.variables[valRefs[0]].value==1)}"
      [attr.stroke-width]="shapesService.doors[dynamic.shape].strokeWidth" fill="none"
      [attr.d]="shapesService.doors[dynamic.shape].closed_d">
      <animateTransform [attr.id]="dynamic.id+'close'"
                          attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          [attr.from]="shapesService.doors[dynamic.shape].closefrom"
                          [attr.to]="shapesService.doors[dynamic.shape].closeto"
                          dur="1s"
                          repeatCount="1"/>    </path>

    <g class="metadata">
      <rect id=meta (click)="endAnimation()" class="metabg" x="-10" y="-10" width="70" height="70" />
      <text x="0" y="5">{{dynamic.variables[valRefs[0]].value}}</text>
    </g>
  </g>
</svg>