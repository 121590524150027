// angular
import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
// Auth service
import { AuthService } from './auth.service';
// Prime ng
import {MessageService} from 'primeng/api';
//
import {AppComponent} from '../app.component';
import { AppConfig } from '../shared/services';


@Component({
    selector: 'app-register',
    templateUrl: './app.register.component.html',
    providers: [MessageService]
})
export class AppRegisterComponent implements OnInit {

    loading = false;

    familyName: string;
    givenName: string;
    email: string;
    password: string;
    confirmPassword: string;


    constructor(public app: AppComponent, private authService: AuthService, private router: Router,
                private messageService: MessageService,
                public appConfig: AppConfig) {
    }

    ngOnInit(): void {

    }

    async onSubmit(e: Event) {
      e.preventDefault();
      this.loading = true;
      const result = await this.authService.createAccount(this.email, this.password, this.familyName, this.givenName);
      this.loading = false;
      if (result.isOk) {
        this.messageService.add({severity: 'success', summary: 'Registration succeeded',
            detail: 'Your request has been received. An Administrator will notify you once access has been granted'});
      } else {
        this.messageService.add({severity: 'error', summary: 'Registration failed',
            detail: 'Please check with a system administrator'});
      }
    }
  
    passwordsMatch(): boolean {
      return this.password === this.confirmPassword;
    }

}
