import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { SetSelectedAsset } from './asset-tree.actions';
import {MenuItem, TreeNode} from 'primeng/api';


export class AssetTreeStateModel {
    selectedItem: TreeNode;
}

@State <AssetTreeStateModel>({
    name: 'assets',
    defaults: {
        selectedItem: null,
    }
})

@Injectable()
export class AssetTreeState {

    @Selector()
    static getSelectedAsset(state: AssetTreeStateModel) {
        return state.selectedItem;
    }

    @Action(SetSelectedAsset)
    setSelected({getState, patchState}: StateContext<AssetTreeStateModel>, {payload}: SetSelectedAsset){
      const state = getState();
      patchState({
        selectedItem: payload.item
       });
    }

}
