import { ScreenTabs, TabList } from '../../models/scada-screens';

export class SetTabList {
  static readonly type = '[SCADA] SetTabList';
  constructor(public payload: TabList){}
}

export class ClearTabList {
  static readonly type = '[SCADA] ClearTabList';

  constructor(public payload: string){}
}

export class SetMetaData {
  static readonly type = '[SCADA] SetMetaData';
  constructor(public payload:[{}]){}
}

export class ClearMetaData {
  static readonly type = '[SCADA] ClearMetaData';

  constructor(public payload: string){}
}

export class SetDynamics {
  static readonly type = '[SCADA] SetDynamics';

  constructor(public payload: any) {}
}

export class ClearDynamics {
  static readonly type = '[SCADA] ClearDynamics';

  constructor(){}
}

export class SetDynLookup {
  static readonly type = '[SCADA] SetDynLookup';

  constructor(public payload: any) {}
}

export class ClearDynLookup {
  static readonly type = '[SCADA] ClearDynLookup';

  constructor(){}
}

export class SetAlarms {
  static readonly type = '[SCADA] SetAlarms';

  constructor(public payload: any) {}
}

export class ClearAlarms {
  static readonly type = '[SCADA] ClearAlarms';

  constructor(){}
}

export class SetSelectedTab {
  static readonly type = '[SCADA] SetSelectedTab';

  constructor(public payload: number) {}
}

export class ClearSelectedTab {
  static readonly type = '[SCADA] ClearSelectedTab';

  constructor(){}
}


