
import { ShapesService } from '../../../shared/services/shapes.service';
import { DatePipe } from '@angular/common';

import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { LiveDataService } from 'src/app/shared/services/live-data.service';
import { PrimeNGConfig } from "primeng/api";
import { SaiUtilsService } from 'src/app/shared/services/sai-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { SaiSessionState } from 'src/app/state';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';

interface ShapeInfo {
  outline_d: string,
  outlineFillColor: string,
  outlineStrokeWidth: number,
  outlineStrokeColor: string,
  floodFillColor: string,
  flood_d: string,
  floodStrokeWidth: number,
  floodStrokeColor: string,
  floodBGColor: string,
  statusPath_d: string,
  statusOkColor: string,
  statusOffColor: string,
  statusAlarmColor1: string,
  statusAlarmColor2: string,
  statusStrokeWidth: number,
  additional_d: string,
  additionalStrokeColor: string,
  additionalStrokeWidth: number,
  additionalFillColor: string,
  positions: any,
};

@Component({
  selector: '[app-fan]',
  templateUrl: './fan.component.html',
  styleUrls: ['./fan.component.scss'],
})
export class FanComponent implements OnInit, OnDestroy {
  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild('floodarea', { static: true }) floodarea: ElementRef;
  @ViewChild('filt', { static: true }) myfilt: ElementRef;
  @ViewChild('offsk', { static: true }) offs: ElementRef;


  public _val: number;

  public positions: any;
  public pathArr: string[];
  public additionalPaths: string[];

  public off = true;
  public wasOff = true;
  public inalarm = false;
  public measalarms: boolean[];
  public alarmReasons: string[];
  public metaDataVisible = false;
  public chartVisible = false;
  public chartShow = false;
  public positionOf: string = '';
  private datePipe = new DatePipe('en-US');
  public measvals: any;
  public statVal: string;
  public chartData: string;
  public chartCleanTimer = null;
  public speed: number;
  public shapeInfo: ShapeInfo;
  public valRefs: string[];
  public starting = true;
  public legendText:string;

  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;


   @Input() metaData: any[];

  @Input() dynamic: any;

  public get measTrig() {
    return this._measTrig;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    if (!this.starting) {
      this.renderer.setAttribute(
        this.offs.nativeElement,
        'dy',
        this.dynamic.variables[this.valRefs[0]].value === null 
          ? 
          '1':
          ( 1 -
            (this.dynamic.variables[this.valRefs[0]].value - this.dynamic.range.min) /
            (this.dynamic.range.max - this.dynamic.range.min)
          ).toString()
      );

      this.off = (this.dynamic.variables[this.valRefs[0]].value < 10);
      if (this.off && !this.wasOff) {
        this.wasOff = true;

        setTimeout(() => {
          if (this.off) {
            this.speed = 0
          };
        }, 10000)
      }
      if (this.wasOff && !this.off) {
        this.wasOff = false
      }

    }
  }

  @Input() alarmArray: any;
  public _alarmTi: Date;
  @Input()
  set alarmTi(alarmTi: Date) {
    this._alarmTi = alarmTi;

    this.measalarms = this.alarmArray.map((al) => {
      if (al.t) {
        return true;
      } else {
        return false;
      }
    });

    if (this.alarmArray.filter((a) => a.t).length > 0) {
      this.inalarm = true;
    } else {
      this.inalarm = false;
    }
  }

  @Output() givenCommand = new EventEmitter<{
    ts: number;
    sourceElem: string;
    command: string;
  }>();

  giveCommand({ source, command }) {
    this.givenCommand.emit({
      ts: new Date().valueOf(),
      sourceElem: source,
      command: command,
    });
  }

  public path: string;
  public path2: string;

  public svgTransform: string;

  showMetaData() {
    this.metaDataVisible = true;
  }

  showChart() {
    this.chartVisible = true;
    this.chartShow = true;
  }

  closeChart() {
    this.chartShow = false;
  }

  setSpeed(v: number) {
    this.speed = v;
    const cmd = {
      ts: new Date().valueOf(),
      sourceItem: "fan1",
      sourceElem: "fan1",
      command: v,
    };
    this.liveDataService.commands.next(cmd);

  }

  constructor(
    private renderer: Renderer2,
    private shapesService: ShapesService,
    private liveDataService: LiveDataService,
    public translate: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private utils: SaiUtilsService,
  ) { }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.shapeInfo = this.shapesService.shapeInfo[this.dynamic.shape];
    this.valRefs = Object.keys(this.dynamic.variables);
    this.renderer.setAttribute(
      this.element.nativeElement,
      'id',
      this.valRefs[0]
    );

    const secfilterid = 'flood' + this.dynamic.tab + '.' + this.valRefs[0];
    this.renderer.setAttribute(this.myfilt.nativeElement, 'id', secfilterid);
    this.renderer.setAttribute(
      this.offs.nativeElement,
      'dy', '1'
    );
    this.sessionLanguageSub=this.sessionLanguage$.subscribe(lang =>{ 
      this.legendText=this.translate.instant('SCADA.' + this.dynamic.legend);
    });
    this.renderer.setAttribute(
      this.floodarea.nativeElement,
      'filter',
      'url(#' + secfilterid + ')'
    );

    this.positions = this.shapesService.positions[this.dynamic.shapeType];
    this.positionOf = '#' + this.dynamic.ref;
    this.chartData = this.utils.getNested(this.dynamic, 'options', 'chartData');
    if (this.dynamic.variables['stat']) {
      this.statVal = this.dynamic.variables.stat.ref
    }
    this.starting = false;
  }

  ngOnDestroy(): void {
    this.sessionLanguageSub.unsubscribe();
  }
}
