<div style="margin-left: 1rem;margin-bottom: 1rem;margin-top: 1rem">
	<button pButton pRipple type="button" (click)="expandAll()" style="margin-right: .5rem">{{expandAllLabel}}</button>
	<button pButton pRipple type="button" (click)="collapseAll()">{{collapseAllLabel}}</button>
</div>
<p-tree class="tree" 
    [value]="assets" selectionMode="single" 
	[(selection)]="selectedAsset"
	(onNodeSelect)="nodeSelect($event)" 
	(onNodeUnselect)="nodeUnselect($event)" 
	filter="true" 
	[contextMenu]="cm">
</p-tree>
<p-contextMenu #cm [model]="items"></p-contextMenu>