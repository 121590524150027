<svg [id]="dynamic.ref">

  <g class="bg-sv" [attr.transform]="svgTransform">


    <g *ngFor="let reading of valRefs; let i=index">
      <text class="text"
      [attr.x]=items[reading].legend.x 
            [attr.y]=items[reading].legend.y
            [attr.font-size]=items[reading].legend.s
            [attr.text-anchor]=items[reading].legend.a
            [innerHTML]="legends[reading]"> 
            </text>
      <text class="text" [attr.x]=items[reading].value.x 
            [attr.y]=items[reading].value.y
            [attr.font-size]=items[reading].value.s
            [attr.text-anchor]=items[reading].value.a > 
            {{dynamic.variables[reading].value}}
           </text>
    </g>


  </g>

</svg>
