import { Injectable } from '@angular/core';
// services
import {ApiRequestService} from './api-request.service';
// primeng api
import {TreeNode} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private api: ApiRequestService) {
  }

  private isMetric(metric: TreeNode) {
    return metric.parent !== undefined;
  }

  private getParent(metric: TreeNode) {
    if (this.isMetric(metric)) {
      const parent = metric.parent;
      return parent.data;
    }
  }

  public getTimeSeries(device: string, sensor: string, metric: string, timerange: string) {
    return this.api.getMeasurementsForMetric(device, sensor, metric, timerange);
  }

  public async getChartData(device: string, metrics: TreeNode[], timerange): Promise<any> {
    // build up the pipeline of backend api calls
    const promiseCollection = [];
    let result = [];
    // api call per metric
    metrics.forEach((metric) => {
      const sensor = this.getParent(metric);
      // calling api
      const apiResult = this.getTimeSeries(device, sensor, metric.data.name, timerange);
      // adding promise to collection array
      promiseCollection.push(apiResult);
    });
    // launch all api calls and await until all have completed
    const allData = await Promise.all(promiseCollection);
    allData.forEach((data ) => {
      if (data[0]) {
        result = [...result, data[0]];
      }
    });

    return result;
  }

}
