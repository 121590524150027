import { Measurements } from '../../models/scada-screens';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  SetConnected,
  SetParsedAlarms,
  SetParsedMetaData,
  SetParsedMeasurements,
  ClearParsedMeasurements,
  ClearParsedAlarms,
  SetParsedRawData,
  ClearParsedRawData,
  SetParsedData,
  ClearParsedData
} from './live-data.actions';

export class LiveDataStateModel {
  parsedMeasurements: {};
  parsedAlarms: {};
  parsedMetaData: any[];
  parsedRawData: {};
  parsedData:{};
  connected: boolean;
}

@State<LiveDataStateModel>({
  name: 'liveData',
  defaults: {
    parsedMeasurements: null,
    parsedAlarms: null,
    parsedMetaData: null,
    parsedRawData: {},
    parsedData:{},
    connected: false,
  },
})
@Injectable()
export class LiveDataState {

  @Selector()
  static getParsedMeasmts(state: LiveDataStateModel) {
    return state.parsedMeasurements;
  }

  @Selector()
  static getParsedAlarms(state: LiveDataStateModel) {
    return state.parsedAlarms;
  }

  @Selector()
  static getParsedMetaData(state: LiveDataStateModel) {
    return state.parsedMetaData;
  }

  @Selector()
  static getParsedRawData(state: LiveDataStateModel) {
    return state.parsedRawData;
  }

  @Selector()
  static getConnectState(state: LiveDataStateModel) {
    return state.connected;
  }

  @Selector()
  static getParsedData(state: LiveDataStateModel) {
    return state.parsedData;
  }

  @Action(SetParsedMetaData)
  setParsedMetaData(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetParsedMetaData
  ) {
    const state = getState();
    patchState({
      parsedMetaData: payload,
    });
  }

  @Action(SetParsedAlarms)
  setParsedAlarms(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetParsedAlarms
  ) {
    const state = getState();
    patchState({
      parsedAlarms: payload,
    });
  }

  @Action(ClearParsedAlarms)
  clearParsedAlarms(
    { getState, patchState }: StateContext<LiveDataStateModel>
  ) {
    const state = getState();
    patchState({
      parsedAlarms: {},
    });
  }

  @Action(SetConnected)
  setConnected(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetConnected
  ) {
    const state = getState();
    patchState({
      connected: payload
    });
  }


  @Action(SetParsedMeasurements)
  setParsedMeasurements(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetParsedMeasurements
  ) {
    const state = getState();
    patchState({
      parsedMeasurements: payload,
    });
  }

  @Action(ClearParsedMeasurements)
  clearParsedMeasurements(
    { getState, patchState }: StateContext<LiveDataStateModel>
  ) {
    const state = getState();
    patchState({
      parsedMeasurements: null,
    });
  }

  @Action(SetParsedRawData)
  setParsedRawData(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetParsedRawData
  ) {
    const state = getState();
    const devices = Object.keys(payload);
    const tt= JSON.stringify(state.parsedRawData);
    const rawData = JSON.parse(tt);
    devices.forEach(device => {
      rawData[device] = payload[device]
    });

    patchState({
      parsedRawData: rawData
    })
  }

  @Action(ClearParsedRawData)
  clearParsedRawData(
    { getState, patchState }: StateContext<LiveDataStateModel>
  ) {
    const state = getState();
    patchState({
      parsedRawData: {},
    });
  }

  @Action(SetParsedData)
  setParsedData(
    { getState, patchState }: StateContext<LiveDataStateModel>,
    { payload }: SetParsedData
  ) {
    const state = getState();
    const devices = Object.keys(payload);
    const temp= JSON.stringify(state.parsedData);
    const Data = JSON.parse(temp);
    devices.forEach(device => {
      Data[device] = payload[device]
    });

    patchState({
      parsedData: Data
    })
  }
  @Action(ClearParsedData)
  clearParsedData(
    { getState, patchState }: StateContext<LiveDataStateModel>
  ) {
    const state = getState();
    patchState({
      parsedData: {},
    });
  }

}
