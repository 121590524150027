import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  Input,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LiveDataService } from 'src/app/shared/services/live-data.service';
import { LiveDataState } from 'src/app/state/live-data/live-data.state';
import { DatePipe, DOCUMENT } from '@angular/common';
import { AppConfig } from 'src/app/shared/services';

@Component({
  selector: 'app-faceplate-screen',
  templateUrl: './faceplate-screen.component.html',
  styleUrls: ['./faceplate-screen.component.scss'],
})
export class FaceplateScreenComponent implements OnInit, OnDestroy {
  @Select(LiveDataState.getParsedMetaData) parsedMetaData$: Observable<any[]>;
  
  public connectedToMqtt = false;
  @Select(LiveDataState.getConnectState) connectedToMqtt$: Observable<boolean>;

  public _tab:number;
  @Input()
  set tab(tab:number) {
     this._tab=tab;
    };
  
  public _dynamics:any;
  @Input()
  set dynamics(dynamics:any) {
     this._dynamics=dynamics;
  };

  public pageElements: any[] = null;
  private elementMetaData: any[];
  private parsedMetaData: any[];
  private selectedItem = '';

  public bg :string;
  public elementMetaDataTrig = 'empty';
  private datePipe = new DatePipe('en-US');
  public date = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');
  public time = this.datePipe.transform(Date.now(), 'HH:mm:ss');
  public closeButtonOptions: any;


  public generalConfig = {"showHeader": true, "showAssets": true, "initialAsset": null};

  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: Store,
    private liveDataService: LiveDataService,
    private appConfig: AppConfig
  ) {
    this.generalConfig = this.appConfig.baseConfig['generalConfig'];
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.connectedToMqtt$.subscribe((connected) => {
      this.connectedToMqtt = connected;
    });
    this.closeButtonOptions = {
      text: 'Close',
      onClick: function (e) {},
    };

    this.parsedMetaData$.subscribe((parsedMetaData: any[]) => {
      if (parsedMetaData) {
        this.parsedMetaData = parsedMetaData;
        this.elementMetaData = this.pageElements.map((pe) => {
          return parsedMetaData.filter(
              (pmt) => pmt.element == pe.scadaElement.id
          );
        });
      }
      this.elementMetaDataTrig = Date().valueOf();
    });

    setInterval(() => {
      this.date = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');
      this.time = this.datePipe.transform(Date.now(), 'HH:mm:ss');
    }, 1000);
  }

  handleCommand(s: any) {
    const cmd = {
      ts: s.ts,
      sourceItem: this.selectedItem,
      sourceElem: s.sourceElem,
      command: s.command,
    };
    this.liveDataService.commands.next(cmd);
  }

  closeIt() {
    window.close();
  }
}
