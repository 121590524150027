import {Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {PrimeNGConfig} from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    menuMode = 'static';

    colorScheme = 'dim';

    menuTheme = 'layout-sidebar-white';

    inputStyle = 'outlined';

    ripple=true;

    constructor(private primengConfig: PrimeNGConfig, private titleService: Title) {
    }

    ngOnInit() {
        this.primengConfig.ripple = this.ripple;
    }
}
