// angular
import { Component, OnInit } from '@angular/core';
// store
import { Select, Store } from '@ngxs/store';
import { AssetTreeState } from '../../state';
// rxjs
import { Observable } from 'rxjs';
// services
import {ApiRequestService, ChartService, NodeService} from '../../shared/services';
// primeng api
import { TreeNode } from 'primeng/api';


@Component({
  selector: 'app-charts-screen',
  templateUrl: './charts-screen.component.html',
  styleUrls: ['./charts-screen.component.scss']
})
export class ChartsScreenComponent implements OnInit {

  @Select(AssetTreeState.getSelectedAsset) selectedItem$: Observable<TreeNode>;

  private selectedItem: TreeNode;
  public sensorTree: TreeNode[];
  public selectedMeasurements: TreeNode[] = [];
  public selectedTimerange: '10m';
  public timeranges = ['10m', '30m', '1h', '3h', '6h', '12h', '1d', '2d', '3d', '30d'];
  public chartData = [];
  private device: string;


  constructor( private store: Store, private nodeService: NodeService, private chartService: ChartService) { }

  ngOnInit(): void {
    this.selectedItem$.subscribe((selectedItem: TreeNode) => {
      this.selectedItem = selectedItem;
      this.device = this.selectedItem?.data?.device;
      this.nodeService.getSensors(this.selectedItem.key).then( (res) => {
        this.sensorTree = res;
      });
      this.chartData = [];
    });
  }

  public isMetric(metric: TreeNode) {
    return metric.parent !== undefined;
  }

  public getMetrics(): TreeNode[]{
        return this.selectedMeasurements.filter( (res) => {
      return this.isMetric(res)});
  }

  public getUom(sensor: string, metric: string): string {
    const measurement = this.selectedMeasurements.filter( (res) => this.isMetric(res) && res.parent.key === sensor && res.data?.name === metric )[0];
    return measurement.data?.uom;
  }

  public async search() {
    this.chartData = [];
    const metrics = this.getMetrics();
    const result = await this.chartService.getChartData(this.device, metrics, this.selectedTimerange);
    // process all result data and push to underlying components
    if (result?.length > 0) {
      const sensors = [... new Set(result.map( (o) => o.s))];
      for (const sensor of sensors) {
        const measResults = result.filter((o) => o.s === sensor);
        this.chartData.push({ s: sensor, m: measResults});
      }
    } else {
      // here we should use toasts to warn that we didn't retrieve any data
      console.log('no chart data retrieved for ', this.device, ' and selected metrics');
    }
  }
}

