<div class="p-col-12 p-xs-6">
    <div class="card">
        <div class="card-header">
            <h4>{{path}}</h4>
        </div>
        <div class="span1">
        <div *ngFor="let donut of gauges">
            <app-simple-donut [config]="donut" [measTrig]="measTrig"></app-simple-donut>
        </div>
    </div>
        <p-table [value]="valRefs" [paginator]="true" [rows]="8" >
            <ng-template pTemplate="body" let-valref>
                <tr>
                    <td> {{dynamic.variables[valref].legend | translate }} </td>
                    <td> {{dynamic.variables[valref].value}} </td>
                    <td> {{dynamic.variables[valref].uom}} </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>