import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { AppConfig } from './shared/services';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    constructor(public appMain: AppMainComponent, public appConfig: AppConfig) {}

    ngOnInit() {
 
    }
}
