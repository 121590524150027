import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsTimeseriesComponent} from './echarts/echarts-timeseries.component';

@NgModule({
  imports: [
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  declarations: [
    EChartsTimeseriesComponent,
  ],
  exports: [
    EChartsTimeseriesComponent,
  ]
})
export class ChartsModule {

  static forRoot(): ModuleWithProviders<ChartsModule> {
    return {
      ngModule: ChartsModule,
      providers: [],
    };
  }
}
