import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    EventEmitter
  } from '@angular/core';
  
  
  @Component({
    selector: '[app-door]',
    templateUrl: './door.component.html',
    styleUrls: ['./door.component.scss']
  })
  export class DoorComponent implements OnInit {
  
    @Input() dynamic: any;

    public get measTrig() {
        return this._measTrig;
      };
    
      public _measTrig: number;
      @Input()
      set measTrig(val: number) {
        this._measTrig = val;
      }
  
    @Input() measvalArray:any[];
  
    @Input() alarmArray: any;
    public _alarmTi: Date;
    @Input()
    get alarmTi(): Date {return this._alarmTi};
    set alarmTi(alarmTi: Date) {
      this._alarmTi = alarmTi;
    }
  
    @Output() givenCommand = new EventEmitter<{ts:number,sourceElem:string,command:string}>();
  
    giveCommand({source,command}) {
      this.givenCommand.emit({
        ts:new Date().valueOf(),
        sourceElem:source,
        command:command}
      );
    }
  
    public svgTransform:string;
    public valRefs: string[];
  
    onClick():void {
      this.giveCommand({source:this.dynamic.ref, command:"click"});
    }
  
    constructor(private el: ElementRef) { }
  
    ngOnInit(): void {
      this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
      this.valRefs=Object.keys(this.dynamic.variables);
    }
  
  }
  