export class SetParsedAlarms {
  static readonly type = '[LIVEDATA] SetParsedAlarms';
  constructor(public payload: {}){}
}

export class SetParsedMetaData {
  static readonly type = '[LIVEDATA] SetParsedMetaData';
  constructor(public payload: any[]){}
}

export class SetConnected {
  static readonly type = '[LIVEDATA] SetConnected';
  constructor(public payload: boolean){}
}

export class SetParsedMeasurements {
  static readonly type = '[LIVEDATA] SetParsedMeasurements';
  constructor(public payload: any){}
}

export class ClearParsedMeasurements {
  static readonly type = '[LIVEDATA] ClearParsedMeasurements';
  constructor(){}
}

export class ClearParsedAlarms {
  static readonly type = '[LIVEDATA] ClearParsedAlarms';
  constructor(){}
}

export class SetParsedRawData {
  static readonly type = '[LIVEDATA] SetParsedRawData';
  constructor(public payload: any){}
}

export class ClearParsedRawData {
  static readonly type = '[LIVEDATA] ClearParsedRawData';
  constructor(){}
}

export class SetParsedData {
  static readonly type = '[LIVEDATA] SetParsedData';
  constructor(public payload: any){}
}

export class ClearParsedData {
  static readonly type = '[LIVEDATA] ClearParsedData';
  constructor(){}
}