import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { AddLanguageToSession } from 'src/app/state';
import { Title } from '@angular/platform-browser';
import { AngularFaviconService } from 'angular-favicon';
// import { default as nlMessages } from '../../../../assets/dictionaries/nl.json';
//import { AddTreeTable } from 'src/app/state/asset-tree/asset-tree.actions';


/*
 * This service loads the initial configuration from local file. Also loads the locale of the previous session
 *
*/

@Injectable({
  providedIn: 'root',
})
export class AppConfig {

  private languages: any[];
  private locale: string;


  constructor(
    private http: HttpClient,
    private store: Store,
    public translate: TranslateService,
    private titleService: Title,
    private ngxFavicon: AngularFaviconService
  ) {
    //loadMessages(nlMessages);
  }


  getLocale() {
    const locale = localStorage.getItem('locale');
    return locale;
  }

  getBrokerClientId(clientId) {
    let ci = localStorage.getItem('brokerClientId');
    if (ci == null) {
      ci = clientId + new Date().valueOf();
      localStorage.setItem('brokerClientId', ci);
    }
    return ci;
  }

  public remote: boolean;
  public baseConfig: any;

  load() {
    this.ngxFavicon.setFavicon('config/favicon.ico');
    const jsonFile = 'config/appconfig.json';
    return new Promise<void>((resolve, reject) => {
      // make sure we reload the app config settings when app is started
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-store',
        }),
      };
      this.http
        .get(jsonFile, httpOptions)
        .toPromise()
        .then((response: any) => {
          this.baseConfig = response;
          this.titleService.setTitle(response['appTitle']);
          this.remote = response.generalConfig['remote'];
          const brokerClientId = this.getBrokerClientId(response.mqttClient);
          response.mqttClient = brokerClientId;
          this.languages = response.languages;
          let langCode = '';
          const langArray = [];
          for (const language of this.languages) {
            langCode = Object(language)['langcode'];
            langArray.push(langCode);
          }
          this.translate.addLangs(langArray);
          this.locale = this.getLocale();
          if (!this.locale) {
            this.locale = response.initLocale;
          }
          this.translate.use(this.locale).subscribe(res => {
            this.store.dispatch(new AddLanguageToSession({ language: this.locale }));
          });;
          resolve();
        })
        .catch((response: any) => {
          reject(
            `Could not load file '${jsonFile}': ${JSON.stringify(response)}`
          );
        });
    });
  }
}
