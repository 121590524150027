// angular
import { Injectable } from '@angular/core';
import { AppConfig } from '.';
// services
import { ApiRequestService } from './api-request.service';

@Injectable({
    providedIn: 'root',
})
export class NodeService {

    constructor(private api: ApiRequestService, private appConfig: AppConfig) {
    }

    getAssets(root: string) {
        if (this.appConfig.remote) {
            return this.api.getAssetTree(root);
        } else {
            const ret = this.appConfig.baseConfig['assetData'];
            if (ret) {
                return Promise.resolve(this.appConfig.baseConfig['assetData']); 
            } else {
                return Promise.reject('no assetdata found');
            }
           
        }
    }

    getSensors(asset) {
        return this.api.getAssetSensors(asset);
    }
}
