<div class="bg" [style.background-image]="'url('+bg+')'">
  <svg class="abs" viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="">
    <svg *ngFor="let dynamic of dynamics; let i=index">
      <g *ngIf="dynamic.shapeType=='floodshape'" app-shaped-gauge [dynamic]="dynamic" [measTrig]="measTrigger">
      </g>
      <g *ngIf="dynamic.shapeType=='slidedoor'" app-slide-door [dynamic]="dynamic" [arrayVal]="measTrigger"
        [measTrig]="measTrigger" (givenCommand)="handleCommand($event)"> </g>
      <g *ngIf="dynamic.shapeType=='swingdoor'" app-swing-door [dynamic]="dynamic" [arrayVal]="measTrigger"
        [measTrig]="measTrigger" (givenCommand)="handleCommand($event)"> </g>
      <g *ngIf="dynamic.shapeType==='fan'" app-fan [dynamic]="dynamic"  [measTrig]="measTrigger">
      </g>
      <g *ngIf="dynamic.shapeType==='valueList'" app-value-list [dynamic]="dynamic"  [measTrig]="measTrigger">
      </g>
      <g *ngIf="dynamic.shapeType=='multiplier'" app-multiplier [dynamic]="dynamic" [measTrig]="measTrigger" >
      </g>
      <g *ngIf="dynamic.shapeType=='uipanel'" app-ui-panel 
               [dynamic]="dynamic" [measTrig]="measTrigger"
               [rawData]="rawData[dynamic.statusSensor]" [rawDataTrig]="measTrigger">
      </g>
      <g *ngIf="dynamic.shapeType=='door'" app-door [dynamic]="dynamic" [arrayVal]="measTrigger"
      [measTrig]="measTrigger" (givenCommand)="handleCommand($event)"> </g>
    </svg>

  </svg>
</div>