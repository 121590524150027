<div class="login-body">
    <p-toast position="bottom-left" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'2000ms'"></p-toast>
    <div class="login-wrapper">
        <div class="login-panel">
            <img  [src]="'config/layout/images/appLogo.png'" class="logo" alt="sAInce-layout" />

            <div class="login-form">
                <h2>Change password</h2>
                <p >Came here by accident? <a routerLink="/login">Login</a></p>
                <div style="text-align:left">Password must include <br> - At least 8 characters <br> - Both uppercase and lowercase characters <br> 
                - At least 1 number <br> - At least 1 special character <br><br></div>
                <input pInputText id="email" placeholder="Email" [(ngModel)]="email"/>
                <input type="password" pPassword [feedback]="false" id="password" placeholder="Password" [(ngModel)]="password"/>
                <input type="password" pPassword id="newPassword" [feedback]="true"placeholder="New password"
                 [(ngModel)]="newPassword"/>
                <input type="password" pPassword [feedback]="true" id="confirmPassword" placeholder="Confirm password" [(ngModel)]="confirmPassword"/>
                <button pButton label="Change password" type="button" (click)="onSubmit($event)"></button>
            </div>

        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Access to your</h1>
                <h1>{{appConfig.baseConfig.appName}}</h1>
                <h1>Account</h1>
                <h3>
                    <div *ngFor="let line of appConfig.baseConfig.appDescription">
                        {{line}}
                    </div>
                </h3>
            </div>

        </div>
    </div>
</div>
