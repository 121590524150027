<div class="login-body">
    <p-toast position="bottom-left" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'2000ms'"></p-toast>
    <div class="login-wrapper">
        <div class="login-panel">
            <img  [src]="'config/layout/images/appLogo.png'" class="logo" alt="logo" />

            <div class="login-form">
                <h2>Login</h2>
                <input pInputText id="email" placeholder="Email" [(ngModel)]="email"/>
                <input type="password" pPassword [feedback]="false" id="password" placeholder="Password" [(ngModel)]="password"/>
                <button pButton label="Login" type="button" (click)="onSubmit($event)"></button>
            </div>

        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Access to your</h1>
                <h1>{{appConfig.baseConfig.appName}} account</h1>
                <h3>
                    <div *ngFor="let line of appConfig.baseConfig.appDescription">
                        {{line}}
                    </div>
                </h3>
            </div>
            <div class="image-footer">
            </div>
        </div>
    </div>
</div>
