<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi pi-chevron-left"></i>
        </a>
        <span class="topbar-separator"></span>
        <p-breadcrumb [model]="items" (onItemClick)="onBreadcrumbClick($event)"></p-breadcrumb>
        <img id="logo-mobile" class="mobile-logo" [src]="'config/layout/images/appLogo.png'" alt="logo" />
    </div>

    <div class="topbar-right">

        <ul class="topbar-menu">
           <!--  <p-dropdown (onChange)="onSelectLanguage($event)" [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="text"></p-dropdown> 
          <li class="search-item">
                <a tabindex="0" (click)="appMain.onSearchClick($event)">
                    <i class="pi pi-search"></i>
                </a>
            </li>
               -->
            <li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">
                    <i class="pi pi-bell"></i>
                    <span class="topbar-badge">{{numberOfAlarms}}</span>
                </a>
                <ul class="notifications-menu fade-in-up">

                    <li role="menuitem" *ngFor="let alarm of alarms">
                        <div class="notification-item">
                            <div class="notification-summary">{{alarm.asset}}</div>
                            <div class="notification-detail">{{alarm.alarm}}</div>
                        </div>
                    </li>
                </ul>
            </li>

            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <i class="pi pi-user profile-image"></i>
                    <span class="profile-name">{{user?.email}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
<!--                     <li>
                        <a href="#">
                            <i class="pi pi-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="pi pi-cog"></i>
                            <span>Settings</span>
                        </a>
                    </li> -->
                    <li>
                        <a>
                            <i class="pi pi-sign-out"></i>
                            <span (click)="logOut()">Logout</span>
                        </a>
                    </li>
                    <li>
                        <a>
                            <i class="pi pi-key"></i>
                            <span routerLink="/changepassword">Change password</span>
                        </a>
                    </li>
                </ul>
            </li>

<!--             <li class="right-sidebar-item">
                <a href="#" tabindex="0" (click)="appMain.onRightMenuClick($event)">
                    <i class="pi pi-align-right"></i>
                </a>
            </li> -->
        </ul>
    </div>
</div>