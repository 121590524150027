// angular
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from '@ngx-translate/core';

// primeng
import { PasswordModule, Password } from "primeng/password";
import { ToastModule } from 'primeng/toast';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToolbarModule } from 'primeng/toolbar';
import { TimelineModule } from 'primeng/timeline';
import { FileUploadModule } from 'primeng/fileupload';
import { RatingModule } from 'primeng/rating';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SliderModule } from 'primeng/slider';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';
import { TreeSelectModule } from 'primeng/treeselect';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CardModule, } from 'primeng/card';


// own modules
import { ChartsModule } from '../@charts/charts.module';

// components
import {
    AppAccessdeniedComponent, AppErrorComponent, AppHelpComponent, AppLoginComponent, AppNotfoundComponent, AppBodypaneComponent,
    ScadaScreenComponent, FaceplateScreenComponent, SlideDoorComponent, SwingDoorComponent, ValueListComponent, MultiplierComponent, ShapedGaugeComponent,
    ChartsScreenComponent, FaceplateComponent, UiPanelComponent, HtmlListComponent, EchartComponent, EchartGaugeComponent,
    OpiPlateComponent, FanComponent, DefaultScreenComponent, SimpleDonutComponent, TryoutComponent,DoorComponent
} from '.';
import { NgxEchartsModule } from 'ngx-echarts';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
    imports: [
        FormsModule,
        RouterModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        DividerModule,
        InputMaskModule,
        InputTextModule,
        ToastModule,
        PasswordModule,
        AccordionModule,
        CalendarModule,
        FullCalendarModule,
        TableModule,
        DialogModule,
        ConfirmDialogModule,
        ToolbarModule,
        TimelineModule,
        FileUploadModule,
        RatingModule,
        RadioButtonModule,
        InputNumberModule,
        InputTextareaModule,
        CheckboxModule,
        TabViewModule,
        OverlayPanelModule,
        SliderModule,
        RippleModule,
        MultiSelectModule,
        TreeSelectModule,
        ChipsModule,
        DropdownModule,
        ScrollPanelModule,
        TranslateModule,
        CardModule,
        ChartsModule.forRoot(),
        NgxEchartsModule
    ],
    declarations: [
        AppAccessdeniedComponent,
        AppErrorComponent,
        AppHelpComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppBodypaneComponent,
        DefaultScreenComponent,
        ScadaScreenComponent,
        FaceplateScreenComponent,
        SlideDoorComponent,
        SwingDoorComponent,
        MultiplierComponent,
        ValueListComponent,
        FanComponent,
        ShapedGaugeComponent,
        ChartsScreenComponent,
        FaceplateComponent,
        OpiPlateComponent,
        DashboardComponent,
        UiPanelComponent,
        HtmlListComponent,
        EchartComponent,
        EchartGaugeComponent,
        SimpleDonutComponent,
        TryoutComponent,
        DoorComponent
    ],
    exports: [
        AppAccessdeniedComponent,
        AppErrorComponent,
        AppHelpComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppBodypaneComponent,
        DefaultScreenComponent,
        ScadaScreenComponent,
        FaceplateScreenComponent,
        SlideDoorComponent,
        SwingDoorComponent,
        MultiplierComponent,
        ValueListComponent,
        FanComponent,
        ShapedGaugeComponent,
        FaceplateComponent,
        OpiPlateComponent,
        CurrencyPipe,
        UiPanelComponent,
        HtmlListComponent,
        DoorComponent
    ],
    providers: [
        MessageService
    ],
})
export class PagesModule {
}
