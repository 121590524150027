import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Renderer2,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ShapesService } from 'src/app/shared/services';


@Component({
  selector: '[app-slide-door]',
  templateUrl: './slide-door.component.html',
  styleUrls: ['./slide-door.component.scss']
})
export class SlideDoorComponent implements OnInit {

  private starting = true;

  private firstChange = true;

  private prevStat: number = null;

  @Input() dynamic: any;

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    if (!this.starting) {
      if (this.firstChange) {
        this.firstChange = false;
      } else {
        if (this.prevStat != this.dynamic.variables[this.valRefs[0]].value) {
          var opening: any = document.getElementById(this.dynamic.id + 'open');
          opening.beginElement();
          var closing: any = document.getElementById(this.dynamic.id + 'close');
          closing.beginElement();
        }
      };
      this.prevStat = this.dynamic.variables[this.valRefs[0]].value;
    };

  }

  endAnimation() {
    var opening: any = document.getElementById(this.dynamic.id + 'open');
    opening.endElement();
    var closing: any = document.getElementById(this.dynamic.id + 'close');
    closing.endElement();
  }

  @Input() alarmArray: any;
  public _alarmTi: Date;
  @Input()
  get alarmTi(): Date { return this._alarmTi };
  set alarmTi(alarmTi: Date) {
    this._alarmTi = alarmTi;
  }

  @Output() givenCommand = new EventEmitter<{ ts: number, sourceElem: string, command: string }>();

  giveCommand({ source, command }) {
    this.givenCommand.emit({
      ts: new Date().valueOf(),
      sourceElem: source,
      command: command
    }
    );
  }

  public svgTransform: string;
  public valRefs: string[];

  public test = "M 261 450 L 4 345 L 4 3 L 261 100 Z";

  onClick(): void {
    this.giveCommand({ source: this.dynamic.ref, command: "click" });
  }

  constructor(public shapesService: ShapesService, private renderer: Renderer2,) { }

  ngOnInit(): void {
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.valRefs = Object.keys(this.dynamic.variables);
    this.starting = false;
  }

  ngAfterViewChecked() {
    if (this.firstChange) {
      var opening: any = document.getElementById(this.dynamic.id + 'open');
      opening.endElement();
      var closing: any = document.getElementById(this.dynamic.id + 'close');
      closing.endElement();
    }
  }

}
