import {
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: '[html-list]',
  templateUrl: './html-list.component.html',
  styleUrls: ['./html-list.component.scss']
})
export class HtmlListComponent implements OnInit {

  @Input() dynamic: any;
  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
  }

  public inalarm=true;
  public off= false;
  

  public rawDataTable=[];

  @Input() rawData: any;
  public _rawDataTrig: number;
  @Input()
  set rawDataTrig(val: number) {
    this._rawDataTrig = val;
    if (this.rawData) {
      this.status=this.rawData.status;
      this.inalarm = this.status!="good"; 
      this.rawDataTable=this.rawData.variables;
    }
  }

  public svgTransform: string;
  public valRefs: string[];
  public status ="";

  constructor() { }

  ngOnInit(): void {
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.valRefs = Object.keys(this.dynamic.variables);
  }

}
