import { Injectable } from '@angular/core';
import { ApiRequestService, AppConfig, LiveDataService, MqttService } from '.';
import { Store, Select } from '@ngxs/store';
import { ClearAlarms, ClearDynamics, ClearTabList, SetAlarms, SetDynamics, SetDynLookup, SetTabList } from './../../state/scada/scada.actions';
import { SaiUtilsService } from './sai-utils.service';
import { AwsIotService } from './aws.iot.service';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ClearParsedData, ClearParsedRawData } from 'src/app/state';


@Injectable({
  providedIn: 'root'
})
export class ScreenloaderService {

  public screenConfig = null;
  private dynLookup: any;

  constructor(
    private store: Store,
    private apiRequest: ApiRequestService,
    private appConfig: AppConfig,
    private utils: SaiUtilsService,
    private mqtt: MqttService,
    private awsiot: AwsIotService,
    private liveDataService: LiveDataService
  ) {

    this.load();
  }

  subscribeToHistory: Function;
  unsubscribeFromHistory: Function;
  subscribeToRawTopics: Function;
  unsubscribeFromRawTopics: Function;

  load() {
    if (this.appConfig.remote) {
      console.log('going remote')
      this.subscribeToRawTopics = this.awsiot.subscribeToRawTopics;
      this.unsubscribeFromRawTopics = this.awsiot.unsubscribeFromRawTopics;
    } else {
      console.log('going local')
      this.subscribeToRawTopics = this.mqtt.subscribeToRawTopics;
      this.unsubscribeFromRawTopics = this.mqtt.unsubscribeFromRawTopics;
    };
  }

  loadScreensFor(asset: any): any {
    console.log(asset)
    if (asset) {
      if (this.appConfig.remote) {
        this.apiRequest.getScreenConfigFor(asset.key).then(sc => {
          this.screenConfig = sc;
          this.getAlarms();
          this.getTabs();
          this.getData();
        });
      } else {
        if (this.appConfig.baseConfig['screens'][asset.key]) {
          this.screenConfig = this.appConfig.baseConfig['screens'][asset.key];
          this.getAlarms();
          this.getTabs();
          this.getData();
        } else {
          this.unsubscribeFromRawTopics();
          this.screenConfig = null;
          this.store.dispatch(new ClearDynamics());
          this.store.dispatch(new ClearParsedRawData());
          this.store.dispatch(new ClearTabList(''));
          var defaultScreen = this.makeDefaultScreenFor(asset);
          this.screenConfig = defaultScreen;
          this.getAlarms();
          this.getTabs();
          this.getData();
        };
      };
    } else {
      this.unsubscribeFromRawTopics();
      this.screenConfig = null;
      this.store.dispatch(new ClearDynamics());
      this.store.dispatch(new ClearParsedRawData());
      this.store.dispatch(new ClearTabList(''));
    }
  }


  makeDefaultScreenFor(asset: any) {
    var assetPath = '';
    var assetLabels = [];
    var defaultScreen = {
      dataSources: {
        mqtt: {
          liveData: {}
        }
      },
      screen: {
        tabs: [
          {
            type: "default",
            tabContent: {
              viewbox: "0,0,1200,800",
              dynamics: []
            },
            id: "t001",
            tabSelector: {
              "icon": "pi pi-video",
              "text": "Real-time monitoring"
            }
          }
        ]
      },
      legends: {}
    };
    this.getChildrenOpiTopics(asset, assetPath, assetLabels, defaultScreen);
    return defaultScreen;
  }


  getChildrenOpiTopics(asset: any, assetPath: string, assetLabels: any[], defaultScreen: any) {
    if (asset.children) {
      asset.children.forEach(child => {
        var newAssetPath = assetPath + "." + asset.key;
        var newAssetLabels = [];
        assetLabels.forEach(assetLabel => {
          newAssetLabels.push(assetLabel)
        });
        newAssetLabels.push(child.label);
        if (this.appConfig.baseConfig['screens'][child.key]) {
          if (this.appConfig.baseConfig['screens'][child.key].dataSources.mqtt.liveData.opi)
            defaultScreen.dataSources.mqtt.liveData[child.key + '.opi'] = this.appConfig.baseConfig['screens'][child.key].dataSources.mqtt.liveData.opi;
          defaultScreen.legends[child.key] = {
            path: newAssetPath + "." + child.key,
            label: child.label
          };
          this.appConfig.baseConfig['screens'][child.key].screen.tabs.forEach(tab => {
            if (this.utils.getNested(tab, 'tabContent', 'dynamics')) {
              tab.tabContent.dynamics.forEach(dyn => {
                if (this.utils.getNested(dyn, 'options', 'opi')) {
                  var newSensors = {};
                  var sensors = Object.keys(dyn.input.sensors);
                  sensors.forEach(sensor => {
                    newSensors[child.key + '.' + sensor] = dyn.input.sensors[sensor];
                  });
                  var newdyn: any = {};
                  newdyn = this.utils.deepClone(dyn);
                  delete newdyn.input.sensors;
                  newdyn.input.sensors = newSensors;
                  newdyn.legend = dyn.legend;
                  newdyn.path = newAssetLabels;
                  defaultScreen.screen.tabs[0].tabContent.dynamics.push(newdyn);
                }
              });
            }
          });
        };
        this.getChildrenOpiTopics(child, newAssetPath, newAssetLabels, defaultScreen);
      });
    }
  }

  getData() {
    if (this.utils.getNested(this.screenConfig, 'dataSources', 'mqtt', 'liveData')) {
      this.store.dispatch(new ClearParsedData());
      this.subscribeToRawTopics(this.screenConfig.dataSources.mqtt.liveData);
       this.apiRequest.getHistory(this.screenConfig.device, '20m').then((res) => {
         let lastMeasurement=lastFromHist(res);
         this.liveDataService.processMqttMessages('first/meas',lastMeasurement,'meas');
      });
      // this.apiRequest.getAlarmStatusses('sec-0028-0042', this.dynLookup).then((res)=>{
      //   this.liveDataService.processMqttMessages('first/alm',res,'alm');
      // });
    } else {
      this.unsubscribeFromRawTopics();
    };
  }

  getAlarms() {
    if (this.utils.getNested(this.screenConfig, 'alarms')) {
      var newAlarms: any = {};
      newAlarms = this.utils.deepClone(this.screenConfig.alarms);
      var oids = Object.keys(newAlarms);
      oids.forEach(oid => {
        newAlarms[oid].active = false;
        newAlarms[oid].id = "";
      })
      this.store.dispatch(new SetAlarms(newAlarms));
    } else {
      this.store.dispatch(new ClearAlarms());
    }
  }

  getTabs() {
    var dynamicList = {};
    this.dynLookup = {};
    var tabs = [];
    var dynIndex = 0;
    if (this.utils.getNested(this.screenConfig, 'screen', 'tabs')) {
      for (var tab of this.screenConfig.screen.tabs) {
        dynamicList[tab.id] = [];
        dynIndex = 0;
        if (this.utils.getNested(tab, 'tabContent', 'dynamics')) {
          for (var dynamic of tab.tabContent.dynamics) {
            var variables = {};
            const sensors = Object.keys(dynamic.input.sensors);
            for (var sensor of sensors) {
              const vals = Object.keys(dynamic.input.sensors[sensor].values);
              for (var val of vals) {
                variables[sensor + '.' + val] = {
                  ref: sensor + '.' + val,
                  sensor: sensor,
                  status: null,
                  valcode: val,
                  uom: dynamic.input.sensors[sensor].values[val].uom,
                  tag: dynamic.input.sensors[sensor].values[val].tag,
                  name: dynamic.input.sensors[sensor].values[val].name,
                  valType: dynamic.input.sensors[sensor].values[val].type,
                  legend: (dynamic.input.sensors[sensor].values[val].legend ? dynamic.input.sensors[sensor].values[val].legend : ""),
                  value: null,
                  inalarm: false,
                  alarms:{},
                  almVal: null,
                  alarmReason: '',
                  almOid: null,
                  almId: null,
                  lastUpdate: null
                };
                if (this.dynLookup[sensor + '.' + val]) {
                  this.dynLookup[sensor + '.' + val].push({
                    "tab": tab.id,
                    "index": dynIndex
                  });
                } else {
                  this.dynLookup[sensor + '.' + val] = [
                    {
                      "tab": tab.id,
                      "index": dynIndex
                    }
                  ];
                };
              }
            };
            dynamicList[tab.id].push({
              id: dynamic.id,
              status: null,
              statusSensor: dynamic.statusSensor,
              tab: tab.id,
              variables: variables,
              states: dynamic.input.states,
              range: dynamic.range,
              legend: dynamic.legend,
              options: dynamic.options,
              x: dynamic.x,
              y: dynamic.y,
              scale: dynamic.scale,
              shape: dynamic.shape,
              shapeType: dynamic.type,
              path: dynamic.path
            });
            dynIndex++;
          };
        };
        tabs.push({
          key: tab.id,
          bg: tab.tabContent.backgroundImage ?
            ((this.appConfig.remote ?
              ('https://' + this.appConfig.baseConfig["backgroundApi"]) :
              'config/scadaBg/') + tab.tabContent.backgroundImage) :
            null,
          type: tab.type,
          viewbox: tab.tabContent.viewbox,
          tabSelector: tab.tabSelector,
          tabContent: tab.tabContent
        });

      };
    } else {
      console.log('empty');
    };
    this.store.dispatch(new SetDynLookup(this.dynLookup));
    this.store.dispatch(new SetDynamics(dynamicList));
    this.store.dispatch(new SetTabList(tabs));
  }

}

const lastFromHist = (histData) => {
  let meas = {
      ts:null,
      res:{}
  };
  let sensors = [];
  meas.ts = histData[0].v.pop().ts;
  histData.forEach(hist => {
      if (sensors.indexOf(hist.s) === -1) {
          sensors.push(hist.s);
          meas.res[hist.s]={
              s: "good",
              v:{}
          }
      };
      meas.res[hist.s].v[hist.m]=hist.v.pop().v
  });
  return meas
};

