<svg>
    <g class="bg-sv" [attr.transform]="svgTransform">
        <rect [ngClass]="{ok : (!inalarm), alarm : (inalarm) }"        
              [attr.width]="dynamic.options.area.width" [attr.height]="dynamic.options.area.height" 
              style="stroke-width:0;stroke:transparent" 
                  transform="translate(0,20)"/>
        <foreignObject width="150" height="250">
            <p-card title="dynamic.legend" class="card" >
                {{dynamic.legend}} 
                <div class="info">
                    <ng-container *ngIf="status=='good'"> 
                        <img [src]="'assets/greenTick.png'" alt="good" width="20" /> 
                     </ng-container>
                    <ng-container *ngIf="status!='good'">
                        <img [src]="'assets/redCross.png'" alt="bad" width="20" /> 
                     </ng-container>
                </div>
                <p-button label="info" (click)="op.toggle($event)"> </p-button>
                <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '340px'}">
                    <ng-template pTemplate>
                        <p-table [value]="rawDataTable" [paginator]="true" [rows]="8">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th >Property</th>
                                    <th >Value </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-variablet>
                                <tr [pSelectableRow]="rowData">
                                    <td>{{variablet.property}}</td>
                                    <td>{{variablet.value}}</td>
                                </tr>
                            </ng-template>
                        </p-table>                   
                    </ng-template>
                </p-overlayPanel>
            </p-card>
        </foreignObject>
        

    </g>
</svg>