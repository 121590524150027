<svg #element>
  <defs>
    <filter #filt id="fillp" primitiveUnits="objectBoundingBox" x="0%" y="0%" width="100%" height="100%">
      <feFlood x="0%" y="0%" width="100%" height="100%" [attr.flood-color]=shapeInfo.floodFillColor />
      <feOffset #offsk>
      </feOffset>
      <feComposite operator="in" in2="SourceGraphic" />
      <feComposite operator="over" in2="SourceGraphic" />
    </filter>
  </defs>
  <g class="bg-sv" [attr.transform]="svgTransform">
    
    <path [attr.d]=shapeInfo.outline_d [attr.stroke]=shapeInfo.outlineStrokeColor
      [attr.stroke-width]=shapeInfo.outlineStrokeWidth [attr.fill]=shapeInfo.outlineFillColor>
    </path>

    <path #floodarea [attr.d]=shapeInfo.flood_d  [attr.stroke-width]=shapeInfo.floodStrokeWidth
    [attr.fill]=shapeInfo.floodBGColor [attr.stroke]=shapeInfo.floodStrokeColor></path>
    
    <path [attr.d]=shapeInfo.statusPath_d 
      [ngClass]="{alarm : (dynamic.variables[valRefs[0]].inalarm && !off), ok : (!dynamic.variables[valRefs[0]].inalarm && !off), off:off }"
      [attr.stroke]=shapeInfo.statusOkColor [attr.stroke-width]=shapeInfo.statusStrokeWidth fill='none'>
      <animateTransform *ngIf="!off" attributeType="xml" attributeName="transform" type="rotate" from="0 213.8 187.7"
          to="360 213.8 187.7" dur="1.5s" additive="sum" repeatCount="indefinite" />
    </path>

    <g>
      <foreignObject x="10" y="10" width="250" height="300" [attr.y]=shapeInfo.positions.legend.y
      [attr.x]=shapeInfo.positions.legend.x>
        <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:30px; background-color:rgba(100,100,100,0.4)"  align="center" [innerHTML]="legendText">
        </div>
      </foreignObject>
    </g>

    <g *ngFor="let reading of valRefs; let i=index">
      <text *ngIf="shapeInfo.positions.tag[i].show" class="legend" [attr.x]=shapeInfo.positions.tag[i].x [attr.y]=shapeInfo.positions.tag[i].y
        [attr.text-anchor]=shapeInfo.positions.tag[i].a
        [attr.font-size]=shapeInfo.positions.tag[i].s>{{dynamic.variables[reading].tag}}</text>
      <text class="uom" [attr.x]=shapeInfo.positions.uom[i].x [attr.y]=shapeInfo.positions.uom[i].y [attr.text-anchor]=shapeInfo.positions.uom[i].a
        [attr.font-size]=shapeInfo.positions.uom[i].s>{{dynamic.variables[reading].uom}}</text>
      <text *ngIf="shapeInfo.positions.measurements[i].show" [class.measurement]="!dynamic.variables[reading].inalarm"
        [attr.x]=shapeInfo.positions.measurements[i].x [attr.y]=shapeInfo.positions.measurements[i].y
        [attr.text-anchor]=shapeInfo.positions.measurements[i].a [attr.font-size]=shapeInfo.positions.measurements[i].s
        [attr.fill]=shapeInfo.positions.measurements[i]>{{dynamic.variables[reading].value}}</text>
    </g>


<!--     <g>
      <foreignObject x="280" y="100" height="40" width="40">
        <div id="metaDataPopup">
          <button pButton pRipple type="button" class="p-button-rounded" icon="pi pi-ellipsis-h" (click)="showMetaData()" [style]="{width: '40px', height: '40px'}"></button>
          <p-dialog [header]="'Gegevens '+dynamic.legend" [(visible)]="metaDataVisible" [style]="{width: '500px'}" appendTo="body" modal
                    [maskStyleClass]="'active'" [contentStyleClass]="'active'" [styleClass]="'active'">
              <div class="p-fluid p-formgrid p-grid">
                  <div *ngFor="let metadt of metaData;" class="p-field p-col-12 p-md-4">
                      <label>{{metadt.legend}}</label>
                      <input type="text" pInputText [(ngModel)]="metadt.v" disabled>
                  </div>
              </div>
              <div class="p-formgrid p-grid command-buttons">
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 0 ? 'command-button redOn' : 'command-button redOff'" (click)="setSpeed(0)">Off</button>
                  </div>
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 20 ? 'command-button greenOn' : 'command-button greenOff'" (click)="setSpeed(20)">20%</button>
                  </div>
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 40 ? 'command-button greenOn' : 'command-button greenOff'" (click)="setSpeed(40)">40%</button>
                  </div>
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 60 ? 'command-button greenOn' : 'command-button greenOff'" (click)="setSpeed(60)">60%</button>
                  </div>
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 80 ? 'command-button greenOn' : 'command-button greenOff'" (click)="setSpeed(80)">80%</button>
                  </div>
                  <div class="p-col-2">
                      <button pRipple [ngClass]="speed == 100 ? 'command-button greenOn' : 'command-button greenOff'" (click)="setSpeed(100)">100%</button>
                  </div>
              </div>
          </p-dialog>
        </div>
      </foreignObject> 
    </g> -->


    <!-- <g fill=rgb(216,216,216)>
      <ellipse cx="280" cy="100" rx="30" ry="30" (click)="showMetaData()"></ellipse>
      <circle style="fill: rgb(0, 0, 0);" cx="268" cy="100" r="3" (click)="showMetaData()"></circle>
      <circle style="fill: rgb(0, 0, 0);" cx="280" cy="100" r="3" (click)="showMetaData()"></circle>
      <circle style="fill: rgb(0, 0, 0);" cx="292" cy="100" r="3" (click)="showMetaData()"></circle>
    </g> -->
  </g>

</svg>