import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';

@Component({
  selector: '[app-multiplier]',
  templateUrl: './multiplier.component.html',
  styleUrls: ['./multiplier.component.scss']
})
export class MultiplierComponent implements OnInit {

  @Input() dynamic: any;

  valRefs: string[];

  public get measTrig() {
    return this._measTrig;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    if (this.valRefs) {
      this._val = this.dynamic.variables[this.valRefs[0]].value;
      this.range = Array.from({ length: this._val }, (value, key) => key);
    };
  }

  @Input() definition: any;

  public _val: number;
  public range: number[];


  @Output() givenCommand = new EventEmitter<{ ts: number, sourceElem: string, command: string }>();

  giveCommand({ source, command }) {
    this.givenCommand.emit({
      ts: new Date().valueOf(),
      sourceElem: source,
      command: command
    }
    );
  }

  public svgTransform: string;

  onClick(): void {
    this.giveCommand({ source: this.definition.scadaElement.id, command: "click" });
  }


  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.valRefs = Object.keys(this.dynamic.variables);
  }
}

