import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppBodypaneComponent} from './pages';
import {AppChangePasswordComponent, AppLoginComponent, AppRegisterComponent, AppForceChangePasswordComponent} from './@auth';
import { AuthGuardService } from './@auth/auth.service';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    {path: '', canActivate: [AuthGuardService], component: AppBodypaneComponent}
                ]
            },
            {path: 'error', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: 'register', component: AppRegisterComponent},
            {path: 'changepassword', component: AppChangePasswordComponent},
            {path: 'forcechangepassword', component: AppForceChangePasswordComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
