import { Component } from '@angular/core';

import {AppComponent} from './app.component';
import { AppConfig } from './shared/services';

@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
  constructor(public app: AppComponent,public appConfig: AppConfig) {}
}
