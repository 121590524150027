// angular
import { Component, OnDestroy, OnInit } from '@angular/core';
// store
import { Select, Store } from '@ngxs/store';
import { SaiSessionState, SetSelectedAsset } from './state';
// services
import { AppConfig, NodeService } from './shared/services';
import { BreadcrumbService } from './app.breadcrumb.service';
// primeng api
import { MenuItem, TreeNode } from 'primeng/api';
import { ScreenloaderService } from './shared/services/screenloader.service';
import { SaiUtilsService } from 'src/app/shared/services/sai-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable, Subscription } from 'rxjs';
import { ChildrenOutletContexts } from '@angular/router';
import { AppMainComponent } from './app.main.component';

type SaiTreeNode = TreeNode & {
    translateCode: string,
    children: SaiTreeNode[]
};

@Component({
    selector: 'app-tree',
    templateUrl: './app.tree.component.html',
    styles: ['tree { margin-left:1rem; margin-right:1rem }']
})
export class AppTreeComponent implements OnInit, OnDestroy {

    assets: SaiTreeNode[];
    root: SaiTreeNode;
    selectedAsset: SaiTreeNode;
    items: MenuItem[];
    customer: string;
    expandAllLabel = "Expand All";
    collapseAllLabel = "Collapse All";


    @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
    private sessionLanguageSub: Subscription;


    constructor(private store: Store, private nodeService: NodeService,
        private breadcrumbService: BreadcrumbService,
        private appConfig: AppConfig,
        private screenLoader: ScreenloaderService,
        private utils: SaiUtilsService,
        private translate: TranslateService,
        private appMain: AppMainComponent) { }

    ngOnDestroy(): void {
        if (this.sessionLanguageSub) {
            this.sessionLanguageSub.unsubscribe()
        };
    }

    ngOnInit() {
        this.customer = this.appConfig.baseConfig['customer'];
        this.nodeService.getAssets(this.customer).then(nodes => {
            this.assets = nodes;
            this.sessionLanguageSub = this.sessionLanguage$.subscribe(lang => {
                this.expandAllLabel = this.translate.instant('GUI.Expand-all');
                this.collapseAllLabel = this.translate.instant('GUI.Collapse-all');
                const updateChildren = children => {
                    children.forEach(child => {
                        child.label = this.translate.instant('ASSETS.' + child.translateCode);
                        if (child.children) { updateChildren(child.children) };

                    });
                };
                this.assets.forEach(asset => {
                    asset.label = this.translate.instant('ASSETS.' + asset.translateCode);
                    if (asset.children) { updateChildren(asset.children); };
                });
                const pathNodes = this.calculatePath(this.selectedAsset, [this.selectedAsset]);
                this.breadcrumbService.setItems(this.pathToCrumbs(pathNodes));

            });
            this.root = nodes[0];
            const initialAsset = this.appConfig.baseConfig.generalConfig.initialAsset;

            if (initialAsset) {
                this.selectedAsset = this.selectNode(this.assets, initialAsset)
            } else {
                this.selectedAsset = this.root;
            };
            this.store.dispatch(new SetSelectedAsset({ item: this.selectedAsset }));

            // it takes a while before 'parent' member is added to the nodes. Therefor this little timeout before calculating 
            // the path and breadcrumbs
            setTimeout(() => {
                const pathNodes = this.calculatePath(this.selectedAsset, [this.selectedAsset]);
                this.breadcrumbService.setItems(this.pathToCrumbs(pathNodes));
            }, 500);


            this.screenLoader.loadScreensFor(this.selectedAsset);
            if (!this.appConfig.baseConfig.generalConfig.showAssets) {
                this.appMain.staticMenuDesktopInactive = true;
            };
        });



    }

    nodeSelect(event) {
        this.selectedAsset = event.node;
        // calculate path for breadcrumb
        const pathNodes = this.calculatePath(this.selectedAsset, [this.selectedAsset]);
        this.breadcrumbService.setItems(this.pathToCrumbs(pathNodes));
        // push selected asset through to store so it can be used by other components as well
        this.store.dispatch(new SetSelectedAsset({ item: this.selectedAsset }));
        // store in local storage for cache reuse
        localStorage.setItem('selectedItem', this.selectedAsset.key);
        this.screenLoader.loadScreensFor(this.selectedAsset);
    }

    pathToCrumbs(path: TreeNode[]): {}[] {
        const ret = [];
        if (path[0]) {
            for (const pathNode of path) {
                ret.push(
                    {
                        id: pathNode.key,
                        label: pathNode.label
                    }
                )
            }
        }
        return ret;
    }

    nodeUnselect(event) {
        // do nothing yet
    }

    expandAll() {
        this.assets.forEach(node => {
            this.expandRecursive(node, true);
        });
    }

    collapseAll() {
        this.assets.forEach(node => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    private calculatePath(node: TreeNode, path: TreeNode[]): TreeNode[] {
        if (this.utils.getNested(node, "parent")) {
            const parent = node.parent;
            var p = {
                data: parent.data,
                icon: parent.icon,
                key: parent.key,
                label: parent.label,
                children: null,
                expanded: parent.expanded
            };
            path.push(p);
            return this.calculatePath(p, path);
        }
        return path.reverse();
    }

    viewMonitor(node: TreeNode) {
        // do nothing yet
    }

    viewCharts(node: TreeNode) {
        // do nothing yet
    }

    selectNode(tree: SaiTreeNode[], key: string): SaiTreeNode {
        // Iterate through each node of the tree and select nodes
        var child: SaiTreeNode;
        for (const node of tree) {
            // If the current nodes key is in the list of keys to select, or it's parent is selected then select this node as well
            if (key == node.key) {
                return node;
            }
            // Look at the current node's children as well
            if (node.children)
                child = this.selectNode(node.children, key);
            if (child) return child
        }
    }
}
