import { AssetTreeState } from '../../state/asset-tree/asset-tree.state';
import {
  Component,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
  Input,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ScadaState } from 'src/app/state/scada/scada.state';
import { LiveDataService } from 'src/app/shared/services/live-data.service';
import { LiveDataState } from 'src/app/state/live-data/live-data.state';
import { DatePipe, DOCUMENT } from '@angular/common';
import { AppConfig } from 'src/app/shared/services';

@Component({
  selector: 'app-scada-screen',
  templateUrl: './scada-screen.component.html',
  styleUrls: ['./scada-screen.component.scss'],
})
export class ScadaScreenComponent implements OnInit, OnDestroy, AfterViewInit {
  @Select(ScadaState.getSelectedTab) selectedTab$: Observable<any>;
  private selectedTabSub:Subscription;
 
  @Select(LiveDataState.getParsedData) parsedData$: Observable<any[]>;
  private parsedDataSub: Subscription;

  @Select(LiveDataState.getParsedMetaData) parsedMetaData$: Observable<any[]>;
  private parsedMetadataSub: Subscription
   
  @Select(LiveDataState.getParsedRawData) parsedRawData$: Observable<any[]>;
  private parsedRawDataSub: Subscription

  @Select(LiveDataState.getParsedAlarms) parsedAlarms$: Observable<any[]>;
  private parsedAlarmsSub: Subscription
  
  public connectedToMqtt = false;
  
  @Select(LiveDataState.getConnectState) connectedToMqtt$: Observable<boolean>;
  private connectedToMqttSub: Subscription

  private _tab: any;
  @Input()
  set tab(tab: any) {
    this._tab = tab;
  };

  public get tab() {
    return this._tab;
  };

  private _dynamics: any;
  @Input()
  set dynamics(dynamics: any) {
    this._dynamics = dynamics;
  };

  public get dynamics() {
    return this._dynamics;
  };

  public tabList: any = [];
  public pageElements: any[] = null;
  public elementMeasArray: any[];
  private elementMetaData: any[];
  private parsedMetaData: any[];
  private parsedAlarms: {};
  private elementAlmsArrayPc: any[][];
  private selectedTab = 0;
  private selectedItem = '';
  public value = 20;

  public bg: string;
  public elementMetaDataTrig = 'empty';
  private datePipe = new DatePipe('en-US');
  public date = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');
  public time = this.datePipe.transform(Date.now(), 'HH:mm:ss');
  public measTrigger: number=0;
  public rawData = {};
  public rawDataTrigger: number=0;
  public alarmTrigger: number=0;
  public closeButtonOptions: any;


  public generalConfig = { "showHeader": true, "showAssets": true, "initialAsset": null };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private store: Store,
    private liveDataService: LiveDataService,
    private appConfig: AppConfig
  ) {
    this.generalConfig = this.appConfig.baseConfig['generalConfig'];

  }
  ngAfterViewInit(): void {
    // this.openFullscreen();
  }
  ngOnDestroy(): void {
    this.selectedTabSub.unsubscribe();
    this.parsedDataSub.unsubscribe();
    this.parsedRawDataSub.unsubscribe();
    this. parsedAlarmsSub.unsubscribe();
    this.connectedToMqttSub.unsubscribe();

   }

  ngOnInit(): void {
    this.bg = this.tab["bg"];
    this.connectedToMqttSub=this.connectedToMqtt$.subscribe((connected) => {
      this.connectedToMqtt = connected;
    });

    this.closeButtonOptions = {
      text: 'Close',
      onClick: function (e) { },
    };

    this.selectedTabSub=this.selectedTab$.subscribe((tab)=> {
      this.measTrigger=0;
      this.rawDataTrigger=0;
    })


    this.parsedDataSub=this.parsedData$.subscribe((parsedData:{}) => {
      var rd={};
      var ks=Object.keys(parsedData);
      ks.forEach(item=>{
        if (parsedData[item].vars) {
          rd[item]={
            status:parsedData[item].status
          };
          rd[item].variables=[];
          parsedData[item].vars.forEach(el => {
            rd[item].variables.push({
              property:el,
              ...parsedData[el]
            });
          });
        }
      })
      this.rawData=rd;
      if (parsedData) {
        this.measTrigger<3? this.measTrigger++ : this.measTrigger=1; 
      };
    });

    this.parsedRawDataSub=this.parsedRawData$.subscribe((parsedRawData: any) => {
      if (parsedRawData) {
        this.rawDataTrigger<3? this.rawDataTrigger++ : this.rawDataTrigger=1; 
      };
    });

    this.parsedAlarmsSub=this.parsedAlarms$.subscribe((parsedAlarms: any) => {
      if (parsedAlarms) {
        this.measTrigger<3? this.measTrigger++ : this.measTrigger=1; 
      };
    });

  }

  handleCommand(s: any) {
    const cmd = {
      ts: s.ts,
      sourceItem: this.selectedItem,
      sourceElem: s.sourceElem,
      command: s.command,
    };
    this.liveDataService.commands.next(cmd);
  }

  closeIt() {
    window.close();
  }
}
