import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConsoleLogger } from '@aws-amplify/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { TreeNode } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { AppConfig } from 'src/app/shared/services/app.config.service';
import { SaiSessionState } from 'src/app/state';
import  { AssetTreeState } from 'src/app/state';
@Component({
  selector: 'app-echart',
  templateUrl: './echart.component.html',
  styleUrls: ['./echart.component.scss']
})

export class EchartComponent implements OnInit {

  @ViewChild('chart', { static: true }) chartRef: ElementRef;

  f1 = (pt) => { return [pt[0], '10%']; };

  @Input()
  public mergeOption: any;

  public doLifeUpdates = true;

  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;

  public loading = false;

  private translatedTags = {};

  private timestreamTags: string[] = [];
  private dynTagToTsTag = {};
  private tsTagToDynTag = {};

  private graphData: any;
  public theme = "dark";

  @Input()
  private device:string;

  public histReq = "";

  private _startDate: Date;
  @Input()
  set startdate(startdate: Date) {
    this._startDate = startdate;
    let now=new Date();
    let endDate = new Date(startdate.getTime());
    endDate.setDate(this._startDate.getDate() + 1);
    if ((now.valueOf() - endDate.valueOf())/60000 > 1 ) {
      this.doLifeUpdates=false
    } else {
      this.doLifeUpdates=true
    };
 
    this.histReq = `https://${this.appConfig.baseConfig['configApi']}/${this.dynamic.options.histapi}?deviceId=${this.device}&start=${this._startDate.toISOString()}&end=${endDate.toISOString()}&values=${this.dynamic.options.values}`;
    this.getData();
  }


  private _dynamic: any;
  @Input()
  set dynamic(dynamic: any) {
    if (dynamic.options.echartOptions.tooltip) {
      if ((dynamic.options.echartOptions.tooltip).position == "[pt[0], '10%']") {
        dynamic.options.echartOptions.tooltip.position = this.f1
      };
    }
    this._dynamic = dynamic;
    for (const meas in this.dynamic.variables) {
      let concat = "";
      meas.split('.').slice(1).forEach((part, index) => {
        concat = concat + (index == 0 ? part : "." + part);
      })
      this.dynTagToTsTag[meas] = concat;
      this.tsTagToDynTag[concat] = meas;
    }
    this.timestreamTags = this.dynamic.options.echartOptions.series.map(serie => {
      return serie.encode.y;
    });
  };

  public get dynamic() {
    return this._dynamic;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    let newSource = [];
    let ts = null;
    if (this.doLifeUpdates) {
      let keys = Object.keys(this.dynamic.variables);
      ts = this.dynamic.variables[keys[0]].lastUpdate;
      if (this.graphData) {
        this.graphData.dimensions.forEach((dim, index) => {
          if (index == 0) {
            newSource.push(ts);
          } else {
            newSource.push(this.dynamic.variables['meas.' + dim].value);
          };
        });
        this.graphData.source.push(newSource);
        if (this.graphData.source.length > 3600) {
          this.graphData.source.shift();
        };
        this.mergeOption = { dataset: this.graphData };
      }
    }
  }

  get measTrig() {
    return this._measTrig;
  }

  changetheme() {
    this.theme = this.theme == "light" ? "dark" : "light";
  }

  constructor(
    public translate: TranslateService, 
    private http: HttpClient, 
    private renderer: Renderer2,
    private appConfig: AppConfig,
  ) { }

  ngOnDestroy(): void {
    if (this.sessionLanguageSub) {
      this.sessionLanguageSub.unsubscribe();
    };

  }

  ngOnInit(): void {
    let keys = Object.keys(this.dynamic.variables);
    this.sessionLanguageSub = this.sessionLanguage$.subscribe(lang => {
      const gaugeData = [];
      keys.forEach(key => {
        this.translatedTags[key] = this.translate.instant('SCADA.' + this.dynamic.variables[key].tag);
        gaugeData.push({
          value: this.dynamic.variables[key].value ? this.dynamic.variables[key].value : 0,
          name: this.translatedTags[key]
        });
      });
    });
    //this.getData();
  }

  ngAfterViewInit(): void {
    //this.renderer.setStyle(this.chartRef.nativeElement,'width','700px')
  }

  getData() {
    this.getHistory('sec-0028-0042', '20m').then((res) => {
      this.graphData = prepEchartDataSet(<Array<any>>res, this.timestreamTags);
      this.mergeOption = { dataset: this.graphData }
    });
  }

  getHistory = (device: string, timerange: string) => {
    return new Promise((resolve, reject) => {
      let res: any;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      //console.log('requesting history');
      res = this.http
        .get(
          this.histReq,
          httpOptions
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        });
      //console.log(res);
      resolve(res);
    })
  }
}

function prepEchartDataSet(data: any[], measurements: string[]) {
  if (data.length > 0) {
    var datasetObj = {};
    var dataSet = [];
    var tsen = [];
    var dimensions = ['timestamp'];

    <Array<any>>data[0].v.forEach(pp => {
      tsen.push(pp.ts);
      datasetObj[pp.ts] = [];
      datasetObj[pp.ts].push(pp.ts);
    })
    data.forEach(dat => {
      if (measurements.includes(dat.s + '.' + dat.m)) {
        dimensions.push(dat.s + '.' + dat.m);
        <Array<any>>dat.v.forEach(tt => {
          datasetObj[tt.ts].push(tt.v);
        })
      }
    });
    <Array<any>>data[0].v.forEach(zz => {
      dataSet.push(datasetObj[zz.ts]);
    })
    return {
      dimensions: dimensions,
      source: dataSet
    }
  } else {
    return {
      dimensions: [],
      source: []
    }
  }

}
