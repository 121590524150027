
<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <p-toolbar>
                <div class="p-toolbar-group-left">
                    <p-treeSelect width="600px" [(ngModel)]="selectedMeasurements" [options]="sensorTree" display="chip" [metaKeySelection]="false" selectionMode="checkbox" placeholder="Select measurement"></p-treeSelect>
                </div>
                <div class="p-toolbar-group-right">
                    <p-dropdown [options]="timeranges" [(ngModel)]="selectedTimerange" placeholder="time range"></p-dropdown>
                    <p-button pRipple pRounded (click)="search()">Apply</p-button>
                </div>
            </p-toolbar>
            <p-scrollPanel [style]="{width: '100%', height: '600px'}">
                <h5>Charts</h5>
                <p-accordion [multiple]="true">
                    <p-accordionTab *ngFor="let tab of chartData" [header]="tab?.s" [selected]="true">
                        <app-echarts-timeseries *ngFor="let chart of tab.m" [name]="chart?.m" [yAxisLabel]="getUom(tab?.s, chart?.m)" [data]="chart?.v"></app-echarts-timeseries>
                    </p-accordionTab>
                </p-accordion>
            </p-scrollPanel>
        </div>
    </div>
</div>
