import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CardModule } from 'primeng/card';
import { Select } from '@ngxs/store';
import { SaiSessionState } from 'src/app/state';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-faceplate',
  templateUrl: './faceplate.component.html',
  styleUrls: ['./faceplate.component.scss'],
})
export class FaceplateComponent implements OnInit, OnDestroy{

  public legendText: string;

  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;

  @Input() dynamic: any;

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;    
  }

  public inAlarm = false;
  public valAlarms ={};

  public valRefs: string[];

  constructor(
    public translate: TranslateService,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.valRefs = Object.keys(this.dynamic.variables);
    this.sessionLanguageSub = this.sessionLanguage$.subscribe(lang => {
      this.legendText = this.translate.instant('SCADA.' + this.dynamic.legend);
    });

  }

  ngOnDestroy(): void {
    this.sessionLanguageSub.unsubscribe();
  }

}