import {Component} from '@angular/core';
import {AppComponent} from '../app.component';

@Component({
    selector: 'app-login',
    templateUrl: './app.login.component.html',
    styles: [`
        :host ::ng-deep .p-password-input {
            width: 100%;
            max-width: 360px;
            margin-left: 0px;
            margin-right: 0px;
            margin-bottom: 20px;
            padding: 7px;

            > p-input-text {
                width: 100%;
                max-width: 360px;
                margin-bottom: 20px;
                padding: 7px;
            }
            
        }
    `
    ]
})
export class AppLoginComponent {

    constructor(public app: AppComponent) {
    }
}
