import { LiveDataState } from './../../state/live-data/live-data.state';
import { LiveDataService } from './live-data.service';
import {
  SetConnected,
} from './../../state/live-data/live-data.actions';
import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
// import { connect, MqttClient } from 'mqtt';
import { Observable } from 'rxjs';
import { connect, MqttClient } from 'mqtt';
// import { NewChartData } from '../../app/state/chart-data/chart-data.actions';
import { AppConfig } from '.';
import { bool } from 'aws-sdk/clients/signer';

@Injectable({
  providedIn: 'root',
})
export class MqttService {
  private mqttClient: MqttClient;

  @Select(LiveDataState.getConnectState) connectedToMqtt$: Observable<boolean>;
  private connectedToMqtt = false;
  private lifebeatReceived = new Date().valueOf();
  private lifeBeatTimer = null;
  private historyFromMqtt: bool;
  private mqttOptions: any;
  public prevMeasTopic: string;
  public prevAlarmTopic: string;
  public rawTopics: any = null;
  public rawTopicKeys = [];
  public prevRawTopics: any = null;
  public reverseRawTopics: any ={};
  private wildcardCounter = 0;

  constructor(
    private store: Store,
    private liveDataService: LiveDataService,
    private appConfig: AppConfig
  ) {

    if (!this.appConfig.baseConfig['generalConfig']['remote']) {
      this.iotConnect();
    };
  }

  iotConnect() {
    return new Promise<void>((resolve, reject) => {
      this.historyFromMqtt = this.appConfig.baseConfig['historyFromMqtt'];
      this.mqttOptions = {
        rejectUnauthorized: false,
        cleanSession: false,
        username: this.appConfig.baseConfig['mqttu'],
        password: this.appConfig.baseConfig['mqttp'],
        clientId: this.appConfig.baseConfig['mqttClient'],
      };

      this.mqttClient = connect(
        this.appConfig.baseConfig['configProtocol'] + '://' + this.appConfig.baseConfig['configurl'],
        this.mqttOptions
      );
      this.mqttClient.on('connect', () => {
        this.listenToMqtt();
      });

      this.liveDataService.commands.subscribe((cmd) => {
        const topic = 'set/' + cmd.sourceElem + '/sp';
        this.mqttClient.publish(
          topic,
          JSON.stringify({ ts: cmd.ts, v: cmd.command })
        );
      });
    });
  }

  listenToMqtt() {
    this.mqttClient.on('message', (topic, message) => {
      if (!this.reverseRawTopics[topic]) {
        this.reverseRawTopics[topic]='_wc'+this.wildcardCounter.toString();
        this.wildcardCounter++;
      };

      this.liveDataService.processMqttMessages(topic,
        JSON.parse(message.toString()),
        this.reverseRawTopics[topic]);
      if (this.lifeBeatTimer) {
        clearTimeout(this.lifeBeatTimer);
      }
      this.lifeBeatTimer = setTimeout(() => {
        this.store.dispatch(new SetConnected(false));
      }, this.appConfig.baseConfig.connectTimeOut*1000);
      if (!this.connectedToMqtt) {
        this.store.dispatch(new SetConnected(true));
      }
      if (topic.includes('alm')) {
        this.liveDataService.processAlarms(JSON.parse(message.toString()),'alm');
      }
      if (topic.includes('sai-gen')) {
        this.lifebeatReceived = new Date().valueOf();
      }
    });
  }

  subscribeToRawTopics(rawTopics:any){
    this['mqtt'].wildcardCounter=0;
    if (this['mqtt'].prevRawTopics) {
      this['mqtt'].rawTopicKeys.forEach(topic=>{
        console.log('unsubscribing from',this['mqtt'].prevRawTopics[topic]);
        this['mqtt'].mqttClient.unsubscribe(this['mqtt'].prevRawTopics[topic]);
      });
    }
    this['mqtt'].rawTopicKeys = Object.keys(rawTopics);
    this['mqtt'].reverseRawTopics={};
    this['mqtt'].rawTopicKeys.forEach(topic=>{
      this['mqtt'].reverseRawTopics[rawTopics[topic]]=topic;
      this['mqtt'].mqttClient.subscribe(rawTopics[topic], function (err) {
        if (!err) {
          console.log('subscribed to ', rawTopics[topic]);
        }
      });
    });
    this['mqtt'].prevRawTopics=rawTopics;
  }
  
  unsubscribeFromRawTopics(){
    if (this['mqtt'].prevRawTopics) {
      this['mqtt'].rawTopicKeys.forEach(topic=>{
        this['mqtt'].mqttClient.unsubscribe(this['mqtt'].prevRawTopics[topic]);
        console.log('unsubscribed from ', this['mqtt'].prevRawTopics[topic]);
      });
      this['mqtt'].prevRawTopics=null;
      this['mqtt'].rawTopicKeys=[];
    }  
  }

}
