// appconfig
import {NgModule, APP_INITIALIZER} from '@angular/core';
import {AppConfig} from './shared/services';

// angular
import {FormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
// app-routing
import {AppRoutingModule} from './app-routing.module';
// primeng modules
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
// app modules
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightmenuComponent} from './app.rightmenu.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppSearchComponent} from './app.search.component';
import {AppFooterComponent} from './app.footer.component';
import {AppTreeComponent} from './app.tree.component';
import { NgPasswordValidatorModule } from 'ng-password-validator';

// main pages module
import { PagesModule } from './pages/pages.module';

// layout services
import {MenuService} from './app.menu.service';

// STORE
import { NgxsModule } from '@ngxs/store';
import { AssetTreeState } from './state/asset-tree/asset-tree.state';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { environment } from 'src/environments/environment';
// state
import { ScadaState } from './state/scada/scada.state';
import { LiveDataState } from './state/live-data/live-data.state';
import { SaiSessionState } from './state/sai-session/sai-session.state';
// translate
import {TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// authentication
import { AuthService, } from './@auth/auth.service';
import Amplify, { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';

// authorization components
import {AppLoginComponent, AppRegisterComponent,
    AppChangePasswordComponent, AppForceChangePasswordComponent} from './@auth';

// amplify configuration



//********************************* Cognito User Pool "AppTest*/

// Amplify.configure({
//     Auth: {
//         mandatorySignIn: false,
//         identityPoolId: 'eu-west-1:265983fa-76fa-40ef-b868-23ecfacfea3f', // 'eu-west-1:f98e3506-1097-4bd2-b263-044b635ed876',
//         region: 'eu-west-1',
//         userPoolId: 'eu-west-1_BjREpuKIo',
//         userPoolWebClientId: '7g2vg3sp5to5ctpfpf88e6h6v',
//         authenticationFlowType: 'USER_PASSWORD_AUTH'
//     }
// });

//*************************************  Cognito User Pool "SAICamDev*/

/* Amplify.configure({
    Auth:{
      mandatorySignIn:false,
      identityPoolId: 'eu-west-1:265983fa-76fa-40ef-b868-23ecfacfea3f', // 'eu-west-1:f98e3506-1097-4bd2-b263-044b635ed876',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_hC86zFM1H',  // SAICamDev
      userPoolWebClientId: '1cccc6fa5okcdm6nj1reo3ked9',  //SAICAMconfigurator
      authenticationFlowType:'USER_PASSWORD_AUTH'
    }
  });


//******************************************** 


// global config for aws sdk
AWS.config.region = 'eu-west-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-1:265983fa-76fa-40ef-b868-23ecfacfea3f'  //MyIdenPool
}); */


Amplify.configure({
    Auth:{
      mandatorySignIn:false,
      identityPoolId: 'eu-west-1:ca03c349-04a4-4945-88ab-dc40a3039955', // 'eu-west-1:f98e3506-1097-4bd2-b263-044b635ed876',
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_gFA0zAJ6M',
      userPoolWebClientId: '27004sohk1odbmd1mn1f2cp7bm',
      authenticationFlowType:'USER_PASSWORD_AUTH'
    }
  });


//******************************************** */


// global config for aws sdk
AWS.config.region = 'eu-west-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-1:ca03c349-04a4-4945-88ab-dc40a3039955'
});






export function initializeApp(appConfig: AppConfig) {
    return () => appConfig.load();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient,'config/i18n/');
}

export class SaiMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        return params.key.split(".",2)[1];
    }
}

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        PagesModule,
        NgPasswordValidatorModule,
        NgxsModule.forRoot([
            AssetTreeState,
            ScadaState,
            SaiSessionState,
            LiveDataState,
        ],{ developmentMode: false }), // must be false since TreeNode also in state and must be mutable
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production, // Set to true for prod mode
            maxAge: 10
        }),
        NgxsLoggerPluginModule.forRoot(
            {disabled: true}
        ),
        TranslateModule.forRoot({
            missingTranslationHandler: {provide: MissingTranslationHandler, useClass: SaiMissingTranslationHandler},
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppRightmenuComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppTopBarComponent,
        AppSearchComponent,
        AppFooterComponent,
        AppTreeComponent,
        AppLoginComponent,
        AppChangePasswordComponent,
        AppForceChangePasswordComponent,
        AppRegisterComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService,
        AuthService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppConfig], multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
