import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { SaiSessionState } from 'src/app/state';

@Component({
  selector: 'app-echart-gauge',
  templateUrl: './echart-gauge.component.html',
  styleUrls: ['./echart-gauge.component.scss']
})
export class EchartGaugeComponent implements OnInit, OnDestroy {

  f1 = (pt) => { return [pt[0], '10%']; };

  @Input()
  public mergeOption: any;

  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;

  public loading = false;

  private translatedTags = {};

  private _dynamic: any;
  @Input()
  set dynamic(dynamic: any) {
    if (dynamic.options.echartOptions.tooltip) {
      if ((dynamic.options.echartOptions.tooltip).position == "[pt[0], '10%']") {
        dynamic.options.echartOptions.tooltip.position = this.f1
      }
    }
    this._dynamic = dynamic;
  };

  public get dynamic() {
    return this._dynamic;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    var keys = Object.keys(this.dynamic.variables);
    const gaugeData = [];
    keys.forEach(key => {
      gaugeData.push({
        value: this.dynamic.variables[key].value ? this.dynamic.variables[key].value : 0,
        name:this.dynamic.legend
        //name: this.translatedTags[key]
      });
    });
    this.dynamic.loading = false;
    this.mergeOption = { series: [{ data: gaugeData }] };
    this.dynamic.loading = true;
  }

  get measTrig() {
    return this._measTrig;
  }

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnDestroy(): void {
    if (this.sessionLanguageSub) {
      this.sessionLanguageSub.unsubscribe()
    };
  }

  ngOnInit(): void {
    this.dynamic.options.echartOptions.series[0].min=this.dynamic.range.min;
    this.dynamic.options.echartOptions.series[0].max=this.dynamic.range.max;
    var keys = Object.keys(this.dynamic.variables);
    this.sessionLanguageSub=this.sessionLanguage$.subscribe(lang => {
      const gaugeData = [];
      keys.forEach(key => {
        this.translatedTags[key] = this.translate.instant('SCADA.' + this.dynamic.variables[key].tag);
        gaugeData.push({
          value: this.dynamic.variables[key].value ? this.dynamic.variables[key].value : 0,
          name:this.dynamic.legend
          //name: this.translatedTags[key]
        });
      });
      this.dynamic.loading = false;
      this.mergeOption = { series: [{ data: gaugeData }] };
      this.dynamic.loading = true;
    })




  }

}
