<div class="login-body">
    <p-toast position="bottom-left" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'2000ms'"></p-toast>
    <div class="login-wrapper">
        <div class="login-panel">
            <img [src]="'config/layout/images/appLogo.png'" class="logo" alt="sAInce-layout" />

            <div class="login-form">
                <h2>Sign Up</h2>
                <p>Already have an account? <a routerLink="/login">Login</a></p>
                <input pInputText id="familyName" placeholder="Family Name" [(ngModel)]="familyName" />
                <input pInputText id="givenName" placeholder="Given Name" [(ngModel)]="givenName" />
                <input pInputText id="email" placeholder="Email" [(ngModel)]="email" />
                <input type="password" pPassword [feedback]="false" id="password" placeholder="Password"
                    [(ngModel)]="password" />
                <input type="password" pPassword [feedback]="false" id="confirmPassword" placeholder="Confirm Password"
                    [(ngModel)]="confirmPassword" />
                <button pButton label="Sign Up" type="button" (click)="onSubmit($event)"></button>
            </div>

        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Access to your</h1>
                <h1>{{appConfig.baseConfig.appName}}</h1>
                <h1>Account</h1>
                <h3>
                    <div *ngFor="let line of appConfig.baseConfig.appDescription">
                        {{line}}
                    </div>
                </h3>
            </div>
            <div class="image-footer">
            </div>
        </div>
    </div>
</div>