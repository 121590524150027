import { OnInit, Component, OnDestroy, OnChanges, Input, SimpleChanges, SimpleChange, HostBinding, ElementRef } from '@angular/core';
// import { Subscription } from 'rxjs';


@Component({
  selector: 'app-echarts-timeseries',
  styleUrls: ['./echarts.component.scss'],
  template: `
    <div echarts [options]="options" class="echart"></div>
  `,
  providers: []
})

export class EChartsTimeseriesComponent implements OnInit, OnDestroy , OnChanges {

  @HostBinding('style.--base-color')
  private echartsColor: any;
  @HostBinding('style.--base-light-color')
  private echartsLightColor: any;
  @HostBinding('style.--base-dark-color')
  private echartsDarkColor: any;
  @HostBinding('style.--base-text-color')
  private echartsTextColor: any;
  @HostBinding('style.--base-text-color-secondary')
  private echartsSecondaryTextColor: any;



  options: any = {};

  @Input() data: any;
  @Input() name = '--';
  @Input() yAxisLabel: string;


  constructor(private elRef: ElementRef) {

  }

  ngOnInit() {
    // first get all binded styles so we can apply theme colors to the charts
    // fetch styles for the host element
    const style = window.getComputedStyle(this.elRef.nativeElement, null);

    // fetch the base colors
    this.echartsColor = style.getPropertyValue('--base-color');
    this.echartsLightColor = style.getPropertyValue('--base-light-color');
    this.echartsDarkColor = style.getPropertyValue('--base-dark-color');
    this.echartsTextColor = style.getPropertyValue('--base-text-color');
    this.echartsSecondaryTextColor = style.getPropertyValue('--base-text-color-secondary');
    this.buildChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const changedData: SimpleChange = changes.data;
    if (changedData !== undefined) { // if indeed data has been changed
      this.data = changedData.currentValue;
      if (!changedData.isFirstChange()) { // do not update before first initialization
        this.buildChart();
      }
    }
  }

  private buildChart() {
    const points = this.data;
    if (points === undefined) {
      return;
    }

    // eCharts requires javascript Dates
    const dataset = [];
    for (const point of points) {
      dataset.push([new Date(point.ts), point.v]);
    }

    this.options = {
          tooltip: {
            trigger: 'axis',
            position: (pt) => {
              return [pt[0], '10%'];
            }
          },
          title: {
            left: 'center',
            text: this.name,
            textStyle: {
                color: this.echartsTextColor
            }
          },
          color: this.echartsColor, // 'rgb(0, 150, 136)',
          toolbox: {
            feature: {
              dataZoom: {
                yAxisIndex: 'none'
              },
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'time',
            boundaryGap: false,
            splitLine: {
                  show: false
            },
            axisLabel: {
              color: this.echartsSecondaryTextColor
            }
          },
          yAxis: {
            name: this.yAxisLabel,
            type: 'value',
            boundaryGap: [0, '100%'],
            splitLine: {
                show: false
            },
            axisLabel: {
                color: this.echartsSecondaryTextColor
            }
          },
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 20
            },
            {
              start: 0,
              end: 20
            }
          ],
          series: [
            {
              name: this.name,
              type: 'line',
              smooth: true,
              symbol: 'none',
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {offset: 0, color: this.echartsLightColor },
                    {offset: 1, color: this.echartsDarkColor }
                  ],
                  global: false
                }
              },  // '#{$primaryColor}'}, //{ color: '#5470c6'},
              data: dataset
            }
          ]
        };

  }

  ngOnDestroy(): void {

  }
}
