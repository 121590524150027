<svg #multiplier>
  <g class="bg-sv" [attr.transform]="svgTransform">
    <g *ngFor="let count of range" attr.transform="translate({{(3-count)*250}}, {{(count-3)*80}})">
      <g >
        <polygon style="fill:#046970;" points="304.424,259.768 461.272,313.144 484.336,482.168 249.336,482.168 " />
        <polygon style="fill:#039388;" points="304.424,259.768 426.192,328.4 373.6,482.168 249.336,482.168 " />
        <polygon style="fill:#19DDAA;" points="179.912,259.768 23.064,313.144 0,482.168 235,482.168 " />
        <polygon style="fill:#09C699;" points="157.888,373.288 23.064,313.144 0,482.168 235,482.168 " />
        <polygon style="fill:#1DB591;" points="179.912,259.768 58.144,328.4 110.736,482.168 235,482.168 " />
        <path style="fill:#00233F;" d="M326.256,90.168c0,40.12-30.216,72-67.504,72h-33.184c-37.264,0-67.504-31.88-67.504-72l0,0
      c0-40.136,30.232-72,67.504-72h33.184C296.032,18.168,326.256,50.032,326.256,90.168L326.256,90.168z" />
        <polygon style="fill:#F9BDA0;" points="302.504,290.168 181.832,290.168 189.88,194.168 294.456,194.168 " />
        <polygon style="fill:#E28F71;" points="302.504,290.168 241.96,290.168 181.832,194.168 294.456,194.168 " />
        <path style="fill:#FCCCB9;" d="M323.64,111.744c0,70.872-35.712,135-81.464,135c-45.768,0-81.48-64.128-81.48-135
      c0-70.88,35.712-82.68,81.48-82.68C287.928,29.064,323.64,40.856,323.64,111.744z" />
        <path style="fill:#F9BDA0;"
          d="M242.176,29.064c45.752,0,81.464,11.8,81.464,82.68c0,70.872-35.712,135-81.464,135" />
        <polygon style="fill:#0C537A;" points="240.192,434.504 168.072,336.64 142.04,266.84 178.728,251.472 242.176,291.624
      305.6,251.472 342.288,266.84 316.256,336.64 244.144,434.504 242.112,437.96 " />
        <polyline style="fill:#04394C;" points="242.176,291.624 305.6,251.472 342.288,266.84 316.256,336.64 244.144,434.504
      242.112,437.96 240.192,434.504 " />
        <g>
          <polygon style="fill:#257AA0;" points="178.728,251.472 242.176,291.624 168.08,330.736 	" />
          <polygon style="fill:#257AA0;" points="305.6,251.472 242.176,291.624 316.248,330.736 	" />
        </g>
        <g>
          <path style="fill:#00233F;" d="M161.16,101c-4.336-1.896-3.472-14.736,0.344-23.424c3.832-8.704,10.456-14.2,14.768-12.296
        c4.344,1.904,7.936,12.784,0.928,19.192" />
          <path style="fill:#00233F;" d="M323.144,100.816c4.344-1.912,3.52-14.744-0.28-23.432c-3.816-8.68-10.408-14.168-14.752-12.256
        c-4.328,1.912-7.96,12.808-0.952,19.192" />
        </g>
        <path style="fill:#19DDAA;" d="M314.832,174.616c0,38.896-32.544,86.432-72.672,86.432s-72.656-47.528-72.656-86.432
      s32.528-13.672,72.656-13.672S314.832,135.712,314.832,174.616z" />
        <path style="fill:#09C699;" d="M169.504,174.616c0-38.904,32.528-13.672,72.656-13.672s72.672-25.232,72.672,13.672
      c0,38.896-32.544,86.432-72.672,86.432" />
        <path style="fill:#039388;"
          d="M242.16,160.944c40.128,0,72.672-25.232,72.672,13.672c0,38.896-32.544,86.432-72.672,86.432" />
        <path style="fill:#19DDAA;" d="M242.16,84.152c34.184,0,64.832,0.128,86.096-2.44C326.688,41,309.408,10.168,268.336,2.168h-52.328
      c-41.08,8-58.344,38.832-59.92,79.536C177.344,84.28,207.992,84.152,242.16,84.152z" />
        <path style="fill:#09C699;" d="M157.168,81.704c21.264,2.568,51.36,3.32,85.536,3.32c34.184,0,64.56-0.312,85.824-2.88
      c-1.568-40.704-19.12-71.976-60.192-79.976h-50.168" />
        <path style="fill:#039388;" d="M243.24,85.904c34.184,0,64.288-0.752,85.552-3.32c-1.56-40.704-19.384-72.416-60.456-80.416h-50.168
      " />
        <circle style="fill:#A4FFE5;" cx="180.384" cy="169.376" r="3.96" />
        <path style="fill:#0C537A;"
          d="M181.392,169.504c-0.184,0.6-0.824,0.936-1.424,0.76l-14.44-4.36c-0.592-0.184-0.92-0.816-0.76-1.424
      l0,0c0.184-0.6,0.816-0.944,1.424-0.76l14.432,4.36C181.232,168.272,181.568,168.904,181.392,169.504L181.392,169.504z" />
        <circle style="fill:#A4FFE5;" cx="182.016" cy="183.4" r="3.592" />
        <path style="fill:#0C537A;" d="M182.928,183.512c-0.168,0.544-0.752,0.856-1.288,0.696l-13.072-3.952
      c-0.544-0.168-0.84-0.736-0.688-1.28l0,0c0.168-0.544,0.736-0.856,1.28-0.696l13.08,3.952
      C182.784,182.4,183.088,182.976,182.928,183.512L182.928,183.512z" />
        <circle style="fill:#A4FFE5;" cx="303.944" cy="169.376" r="3.96" />
        <path style="fill:#0C537A;"
          d="M302.944,169.504c0.176,0.6,0.816,0.936,1.416,0.76l14.432-4.36c0.608-0.184,0.936-0.816,0.768-1.424
      l0,0c-0.184-0.6-0.816-0.944-1.424-0.76l-14.448,4.368C303.096,168.272,302.752,168.904,302.944,169.504L302.944,169.504z" />
        <circle style="fill:#A4FFE5;" cx="302.312" cy="183.4" r="3.592" />
        <path style="fill:#0C537A;" d="M301.392,183.512c0.168,0.544,0.76,0.856,1.296,0.696l13.072-3.952
      c0.544-0.168,0.848-0.736,0.688-1.28l0,0c-0.168-0.544-0.736-0.856-1.288-0.696l-13.072,3.952
      C301.536,182.4,301.24,182.976,301.392,183.512L301.392,183.512z" />
        <path style="fill:#039388;" d="M243.672,341.68c-37.824,0-72.168-6.304-93.76-13.336l31.248,153.824h157.008V330.104
      C314.168,337.136,281.496,341.68,243.672,341.68z" />
        <path style="fill:#1DB591;" d="M300.264,347.968c0,2.464-26.008,9.528-58.096,9.528s-58.096-7.072-58.096-9.528
      c0-2.456,26.008,0.64,58.096,0.64S300.264,345.512,300.264,347.968z" />
        <g>
          <circle style="fill:#09C699;" cx="185.888" cy="383.04" r="6" />
          <circle style="fill:#09C699;" cx="194.248" cy="417.376" r="6" />
          <circle style="fill:#09C699;" cx="202.568" cy="451.688" r="6" />
        </g>
      </g>
    </g>

  </g>

</svg>
