
import { ShapesService } from '../../../shared/services/shapes.service';
import { DatePipe } from '@angular/common';

import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { LiveDataService } from 'src/app/shared/services/live-data.service';
import { PrimeNGConfig } from "primeng/api";
import { SaiUtilsService } from 'src/app/shared/services/sai-utils.service';
import { SaiSessionState } from 'src/app/state';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

interface ShapeInfo {
  outline_d: string,
  outlineFillColor: string,
  outlineStrokeWidth: number,
  outlineStrokeColor: string,
  floodFillColor: string,
  flood_d: string,
  floodStrokeWidth: number,
  floodStrokeColor: string,
  floodBGColor: string,
  statusPath_d: string,
  statusOkColor: string,
  statusOffColor: string,
  statusAlarmColor1: string,
  statusAlarmColor2: string,
  statusStrokeWidth: number,
  additional_d: string,
  additionalStrokeColor: string,
  additionalStrokeWidth: number,
  additionalFillColor: string,
  positions: any,
};

@Component({
  selector: '[app-value-list]',
  templateUrl: './value-list.component..html',
  styleUrls: ['./value-list.component..scss'],
})
export class ValueListComponent implements OnInit, OnDestroy {
  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild('floodarea', { static: true }) floodarea: ElementRef;
  @ViewChild('filt', { static: true }) myfilt: ElementRef;
  @ViewChild('offsk', { static: true }) offs: ElementRef;


  public _val: number;
  public positions: any;
  public pathArr: string[];
  public additionalPaths: string[];

  public off = true;
  public wasOff = true;
  public inalarm = true;
  public measalarms: boolean[];
  public alarmReasons: string[];
  public metaDataVisible = false;
  public chartVisible = false;
  public chartShow = false;
  public positionOf: string = '';
  private datePipe = new DatePipe('en-US');
  public measvals: any;
  public statVal: string;
  public chartData: string;
  public chartCleanTimer = null;
  public speed: number;
  public shapeInfo: ShapeInfo;
  public valRefs: string[];
  public items: {};
  public legends: {} = {};

  @Select(SaiSessionState.getCurrentLanguage) sessionLanguage$: Observable<string>;
  private sessionLanguageSub: Subscription;

  @Input() dynamic: any;

  public get measTrig() {
    return this._measTrig;
  };

  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
  }

  public path: string;
  public path2: string;

  public svgTransform: string;

  showMetaData() {
    this.metaDataVisible = true;
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private shapesService: ShapesService,
    private liveDataService: LiveDataService,
    private primeNGConfig: PrimeNGConfig,
    public translate: TranslateService,
    private utils: SaiUtilsService,
  ) { }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
    this.svgTransform =
      'translate(' +
      this.dynamic.x +
      ',' +
      this.dynamic.y +
      ') scale(' +
      this.dynamic.scale +
      ',' +
      this.dynamic.scale +
      ')';
    this.valRefs = Object.keys(this.dynamic.variables);
    this.sessionLanguageSub = this.sessionLanguage$.subscribe(lang => {
      this.valRefs.forEach(vr => {
        this.legends[vr] = this.translate.instant('SCADA.' + this.dynamic.variables[vr].legend);
      });
    });
    this.items = this.shapesService.shapeInfo[this.dynamic.shape].items;
    this.positions = this.shapesService.positions[this.dynamic.shapeType];
    this.positionOf = '#' + this.dynamic.ref;
  }

  ngOnDestroy(): void {
    this.sessionLanguageSub.unsubscribe();
  }
}
