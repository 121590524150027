<div class="login-body">
    <p-toast [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'2000ms'"></p-toast>
    <div class="login-wrapper">
        <div class="login-panel">
            <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'white') + '.svg'" class="logo" alt="diamond-layout" />

            <div class="login-form">
                <h2>Create Your Free Account</h2>
                <p>Already have an account? <a href="#">Login</a></p>
                <input pInputText id="name" placeholder="Full Name" />
                <input pInputText id="email" placeholder="Email" />
                <p-password id="password" placeholder="Password" [feedback]="false" [toggleMask]="true"></p-password>
                <!--input type="password" pPassword [feedback]="false" [showPassword]="false" id="password" placeholder="Password"/-->
                <button pButton label="CONTINUE" type="button" ></button>
            </div>

        </div>
        <div class="login-image">

            <div class="image-footer">
            </div>
        </div>
    </div>
</div>
