import { TranslateService } from '@ngx-translate/core';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-opi-plate',
  templateUrl: './opi-plate.component.html',
  styleUrls: ['./opi-plate.component.scss'],
})
export class OpiPlateComponent implements OnInit {

  @Input() dynamic: any;

  bqtyRef: string;
  actualRef: string;
  eff: string;


  public _measTrig: number;
  @Input()
  set measTrig(val: number) {
    this._measTrig = val;
    if (this.actualRef) {
      this.gauges[0].val =
        Math.round(this.dynamic.variables[this.actualRef].value /
        this.dynamic.variables[this.bqtyRef].value *100);
    };
    if (this.eff) {
      this.gauges[1].val = this.dynamic.variables[this.eff].value;
    }

  }

  get measTrig(){
    return this._measTrig
  }

  public inAlarm = false;

  public valRefs: string[];
  public path: string = "";

  public gauges = [
    {
      title: "Batch Progress",
      uom: "units",
      min: "0",
      max: "100",
      val: 0
    },
    {
      title: "OEE",
      uom: "%",
      min: "0",
      max: "100",
      val: 0
    }
  ]

  constructor(
    public translate: TranslateService,
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.valRefs = Object.keys(this.dynamic.variables);
    this.valRefs.forEach(valRef => {
      if (valRef.split(".")[3] === "bqty") {
        this.bqtyRef = valRef
      };
      if (valRef.split(".")[3] === "act") {
        this.actualRef = valRef
      };
      if (valRef.split(".")[3] === "eff") {
        this.eff = valRef
      };
    });
    this.dynamic.path.forEach(element => {
      if (this.path === "") {
        this.path = this.path + element
      } else {
        this.path = this.path + " - " + element
      }
    });
    this.path = this.path + ": " + this.dynamic.legend;

  }

}