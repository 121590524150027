<svg #element>
  <g class="bg-sv" [attr.transform]="svgTransform">
    <path [ngClass]="{ok : (dynamic.variables[valRefs[0]].value && !dynamic.variables[valRefs[2]].inalarm),
      alarm : (dynamic.variables[valRefs[0]].value && dynamic.variables[valRefs[2]].inalarm),
                      hidden : !(dynamic.variables[valRefs[0]].value)}"
      [attr.stroke-width]="shapesService.doors[dynamic.shape].strokeWidth" fill="none"
      [attr.d]="shapesService.doors[dynamic.shape].open_d">
      <animateMotion 
                          [attr.id]="dynamic.id+'open'" dur="1s" repeatCount="1" [attr.path]="shapesService.doors[dynamic.shape].closed_d"/>
    </path>
    <path [ngClass]="{ok : (!dynamic.variables[valRefs[0]].value),
                                 hidden : (dynamic.variables[valRefs[0]].value)}"
      [attr.stroke-width]="shapesService.doors[dynamic.shape].strokeWidth" fill="none"
      [attr.d]="shapesService.doors[dynamic.shape].closed_d">
      <animateMotion  [attr.id]="dynamic.id+'close'" dur="1s" repeatCount="1" [attr.path]="shapesService.doors[dynamic.shape].open_d"/>    </path>

    <g class="metadata">
      <rect id=meta (click)="endAnimation()" class="metabg" x="-10" y="-10" width="70" height="70" />
      <text x="0" y="5">{{dynamic.variables[valRefs[0]].value}}</text>
    </g>
  </g>
</svg>