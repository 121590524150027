import { SaiSession } from './../models/saiSession';
import { AddSaiSession, RemoveSaiSession } from '../state/sai-session/sai-session.actions';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import Amplify, { Auth } from 'aws-amplify';
import { AppConfig } from '../shared/services';


const defaultPath = '';

@Injectable()
export class AuthService {
  private _user = {
    username: '',
    pool: {
    },
    Session: null,
    client: {},
    signInUserSession: null,
    authenticationFlowType: '',
    storage: {},
    keyPrefix: '',
    userDataKey: '',
    attributes: {
      sub: '',
      given_name: '',
      family_name: '',
      email: ''
    },
    preferredMFA: ''
  };
  get loggedIn(): boolean {
    return !(this._user.username === '');
  }

  private _lastAuthenticatedPath: string = defaultPath;
  set lastAuthenticatedPath(value: string) {
    this._lastAuthenticatedPath = value;
  }

  constructor(private router: Router, private store: Store) { }


  private sai_session: SaiSession;

  async logIn(email: string, password: string) {

    async function mustForceNewPassword(challenge) {
      if (challenge == "NEW_PASSWORD_REQUIRED") {
        console.log("forcing change password");
        await this.router.navigate(['/forcechangepassword']);
      }
    }

    try {
      this._user = await Auth.signIn(email.toString(), password.toString());
      if (this._user["challengeName"] == "NEW_PASSWORD_REQUIRED") {
        await Auth.completeNewPassword(this._user,"Demo_001");

      };
      const tokens = this._user.signInUserSession;
      if (tokens !== null) {
        this.store.dispatch(new AddSaiSession({
          user: {
            userName: this._user.username, email: this._user.username,
            name: this._user.username
          }, startTime: new Date(), authorityLevel: 'standard'
        }));
        this.router.navigate([this._lastAuthenticatedPath]);
        return {
          isOk: true,
          data: this._user
        };
      }
    }
    catch {
      return {
        isOk: false,
        message: 'Authentication failed'
      };
    }
  }

  async getUser() {
    try {
      if (this._user.username !== '') {
        return {
          isOk: true,
          data: this._user
        };
      } else {
        return {
          isOk: false
        };
      }
    }
    catch {
      return {
        isOk: false
      };
    }
  }

  async createAccount(email, password, familyName, givenName) {
    try {
      // Send request
      const user = Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          given_name: givenName,
          family_name: familyName
        }
      });

      this.router.navigate(['/register']);
      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to create account'
      };
    }
  }

  async forceChangePassword(user, newpasword) {
    await Auth.completeNewPassword(this._user, "Demo_001");
  }

  async changePassword(oldPassword, newPassword, recoveryCode: string) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      if (result == "SUCCESS") {
        return {
          isOk: true
        };
      }
      else {
        return {
          isOk: false,
          message: 'Failed to change password'
        };
      }
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to change password'
      };
    }
  }

  async resetPassword(email: string) {
    try {
      // Send request

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: 'Failed to reset password'
      };
    }
  }

  async logOut() {
    await Auth.signOut().then(() => {
      this._user = {
        username: '',
        pool: {
        },
        Session: null,
        client: {},
        signInUserSession: null,
        authenticationFlowType: '',
        storage: {},
        keyPrefix: '',
        userDataKey: '',
        attributes: {
          sub: '',
          given_name: '',
          family_name: '',
          email: ''
        },
        preferredMFA: ''
      };
      this.store.dispatch(new RemoveSaiSession());
      this.router.navigate(['/']);
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private appConfig: AppConfig) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.appConfig.baseConfig.generalConfig.remote) {


      const isLoggedIn = this.authService.loggedIn;
      const isAuthForm = [
        'login',
        'reset-password',
        'register',
        'change-password/:recoveryCode',
        'changepassword'
      ].includes(route.routeConfig.path);



      if (!isLoggedIn && !isAuthForm) {
        this.router.navigate(['/login']);
      }

      if (isLoggedIn) {
        this.authService.lastAuthenticatedPath = route.routeConfig.path;
      }

      return isLoggedIn || isAuthForm;
    } else {
      return true;
    }
  }

}
