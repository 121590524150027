import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ScadaState, SetSelectedTab } from '../state';


@Component({
  selector: 'app-app.bodypane',
  templateUrl: './app.bodypane.component.html',
  styleUrls: ['./app.bodypane.component.scss']
})
export class AppBodypaneComponent implements OnInit {

  @Select(ScadaState.getTabList) tabList$: Observable<any>;
  @Select(ScadaState.getDynamics) dynamics$: Observable<any>;

  public tabList: any = [];
  public dynamics: any={};
  private selectedTab: number;

  constructor(private store: Store,) { }

  ngOnInit(): void {
    this.selectedTab = 0;
    this.tabList$.subscribe((tabList: any) => {
      if (tabList) {
        this.tabList=tabList;
        } else 
      {
        this.tabList=[];
      }
    });
    this.dynamics$.subscribe((dynamics: any) => {
      if (dynamics) {
        this.dynamics=dynamics;
        } else 
      {
        this.dynamics={};
      }
    });
  }

  handleChange(e) {
    this.selectedTab =  e.index;
    this.store.dispatch(
      new SetSelectedTab(
        this.selectedTab
      )
    );

}

}
