<span class="span1">
    <div> Selecteer dag &nbsp;
        <p-calendar [(ngModel)]="date"></p-calendar> &nbsp;
        <button pButton type="button" label="Laatste 24 hr" (click)="last24($event)"></button>
    </div>
    <div class="tt" *ngFor="let dynamic of dynamics">
        <ng-tempate *ngIf="dynamic.shapeType=='echart-gauge'">
            <app-echart-gauge [dynamic]="dynamic" [measTrig]="measTrigger" ></app-echart-gauge>
        </ng-tempate>
        <ng-tempate *ngIf="dynamic.shapeType=='echart-chart'">
            <app-echart [device]="device" [dynamic]="dynamic" [measTrig]="measTrigger" [startdate]="date"></app-echart>
        </ng-tempate>
    </div>
</span>