<p-tabView (onChange)="handleChange($event)" >
    <p-tabPanel *ngFor="let tab of tabList; let i = index" [header]="tab.tabSelector.text" [leftIcon]="tab.tabSelector.icon"
        [selected]="i == 0">
        <div *ngIf="tab.type=='realtime'">
            <div class="hidden-xs hidden-sm">
                <app-scada-screen [tab]="tab" [dynamics]="dynamics[tab.key]"></app-scada-screen>
            </div>
            <div class="hidden-md hidden-lg">
                <app-faceplate-screen [tab]="tab" [dynamics]="dynamics[tab.key]"></app-faceplate-screen>
            </div>
        </div>
        <div *ngIf="tab.type=='charts'">
            Charts will be lazy-loaded
            <br> since we only start loading after button press, we also have to take into account all errors (e.g. 500
            response codes)
            <hr>
            <app-charts-screen></app-charts-screen>
            <ng-template pTemplate="content">
                
            </ng-template>
        </div>
        <div *ngIf="tab.type=='dashboard'">
           <app-dashboard 
                          [tab]="tab" 
                          [dynamics]="dynamics[tab.key]"></app-dashboard>
        </div>
        <div *ngIf="tab.type=='faceplates'">
            <app-faceplate-screen 
                           [tab]="tab" 
                           [dynamics]="dynamics[tab.key]"></app-faceplate-screen>
         </div>
        <div *ngIf="tab.type=='default'">
            <app-default-screen [tab]="tab" 
                           [dynamics]="dynamics[tab.key]"></app-default-screen>
         </div>
    </p-tabPanel>
</p-tabView>