<div class="outer">
    <div  [innerHTML]="legendText"></div>
    <div class="inner">
        <ng-container *ngIf="dynamic.status=='good'">
            <img [src]="'assets/greenTick.png'" alt="good" width="20" height="30"/> 
        </ng-container>
        <ng-container *ngIf="dynamic.status!='good'">
            <img [src]="'assets/redCross.png'" alt="bad" width="20" height="30" /> 
        </ng-container>
    </div>
</div>


<div *ngFor="let reading of valRefs; let i=index" class="p-col-12 p-md-6 p-lg-3" style="padding: 5px;">
    <container class="tagrow">
        <span class="span1">
            <span class="tagname"> {{dynamic.variables[reading].legend | translate }}</span>
            <span [class.tagvalue]="!dynamic.variables[reading].inalarm"
                [class.tagAlarm]="dynamic.variables[reading].inalarm">{{dynamic.variables[reading].value}}</span>
            <span class="uom">{{dynamic.variables[reading].uom}}</span>
        </span>
    </container>
</div>