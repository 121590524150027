<svg #element>
  <defs>
    <filter #filt id="fillp" primitiveUnits="objectBoundingBox" x="0%" y="0%" width="100%" height="100%">
      <feFlood x="0%" y="0%" width="100%" height="100%" [attr.flood-color]=shapeInfo.floodFillColor />
      <feOffset #offsk>
      </feOffset>
      <feComposite operator="in" in2="SourceGraphic" />
      <feComposite operator="over" in2="SourceGraphic" />
    </filter>
  </defs>
  <g class="bg-sv" [attr.transform]="svgTransform">

    <path [attr.d]=shapeInfo.outline_d [attr.stroke]=shapeInfo.outlineStrokeColor
      [attr.stroke-width]=shapeInfo.outlineStrokeWidth [attr.fill]=shapeInfo.outlineFillColor>
    </path>

    <path #floodarea [attr.d]=shapeInfo.flood_d [attr.stroke-width]=shapeInfo.floodStrokeWidth
      [attr.fill]=shapeInfo.floodBGColor [attr.stroke]=shapeInfo.floodStrokeColor>
    </path>

    <path [attr.d]=shapeInfo.additional_d [attr.stroke]=shapeInfo.additionalStrokeColor
      [attr.stroke-width]=shapeInfo.additionalStrokeWidth [attr.fill]=shapeInfo.additionalFillColor>
    </path>

    <path [attr.d]=shapeInfo.statusPath_d
      [ngClass]="{alarm : inalarm, ok : (!(dynamic.variables[valRefs[0]].inalarm || dynamic.status!='good' ) && !off), off:off }"
      [attr.stroke]=shapeInfo.statusOkColor [attr.stroke-width]=shapeInfo.statusStrokeWidth fill='none'>
    </path>
    <g>
      <foreignObject x="10" y="10" width="250" height="300" [attr.y]=shapeInfo.positions.legend.y
      [attr.x]=shapeInfo.positions.legend.x>
        <div xmlns="http://www.w3.org/1999/xhtml" style="font-size:40px; background-color:rgba(100,100,100,0.4)"  align="center" [innerHTML]="legendText">
        </div>
      </foreignObject>
    </g>
    <g *ngFor="let reading of valRefs; let i=index">
      <text *ngIf="shapeInfo.positions.tag[i].show" class="legend" [attr.x]=shapeInfo.positions.tag[i].x
        [attr.y]=shapeInfo.positions.tag[i].y [attr.text-anchor]=shapeInfo.positions.tag[i].a
        [attr.font-size]=shapeInfo.positions.tag[i].s>{{dynamic.variables[reading].tag}}</text>
      <text class="uom" [attr.x]=shapeInfo.positions.uom[i].x [attr.y]=shapeInfo.positions.uom[i].y
        [attr.text-anchor]=shapeInfo.positions.uom[i].a
        [attr.font-size]=shapeInfo.positions.uom[i].s>{{dynamic.variables[reading].uom}}</text>
      <text *ngIf="shapeInfo.positions.measurements[i].show" [class.measurement]="!dynamic.variables[reading].inalarm"
        [class.alarm]="dynamic.variables[reading].inalarm" [attr.x]=shapeInfo.positions.measurements[i].x
        [attr.y]=shapeInfo.positions.measurements[i].y [attr.text-anchor]=shapeInfo.positions.measurements[i].a
        [attr.font-size]=shapeInfo.positions.measurements[i].s
        [attr.fill]=shapeInfo.positions.measurements[i]>{{dynamic.variables[reading].value}}</text>
    </g>


    <!--     <g>
      <foreignObject width="10" height="10">
        <div id="metaDataPopup">
          <dx-popup [width]="400" [height]="500" [showTitle]="true" [closeOnOutsideClick]="true" [dragEnabled]="true"
            [title]="'Gegevens '+definition.scadaElement.legend" container=".dx-viewport" [shading]="false"
            [(visible)]="metaDataVisible">
            <svg width=500 height=500>
              <g *ngFor="let metadt of metaData; let i=index" class="popupmetadata">
                <text fill=white [attr.x]=10 [attr.y]=(20+25*i)>{{metadt.legend}}: {{metadt.v}}</text>
              </g>
            </svg>
            <dxo-position my="left" [of]="positionOf">
            </dxo-position>
          </dx-popup>
        </div>
      </foreignObject>
    </g> -->




    <!--     <g fill=rgb(216,216,216) *ngIf="metaData.length>0">
      <ellipse cx="200" cy="40" rx="20" ry="10" (click)="showMetaData()"></ellipse>
      <circle style="fill: rgb(0, 0, 0);" cx="192" cy="40" r="3" (click)="showMetaData()"></circle>
      <circle style="fill: rgb(0, 0, 0);" cx="200" cy="40" r="3" (click)="showMetaData()"></circle>
      <circle style="fill: rgb(0, 0, 0);" cx="208" cy="40" r="3" (click)="showMetaData()"></circle>
    </g>
 -->


    <!-- 
    <g fill=rgb(208,234,198) *ngIf="chartData">
      <ellipse cx="120" cy="-30" rx="60" ry="36" (click)="showChart()"></ellipse>
      <path
        d="M 96.216 -44.049 C 97.99 -45.169 108.211 -51.405 109.989 -50.656 C 114.244 -48.866 119.106 -46.348 122.135 -43.8 C 123.926 -42.292 131.423 -46.697 133.094 -47.166 C 134.558 -47.576 138.534 -48.502 139.463 -49.285 C 140.496 -50.155 148.236 -45.024 149.386 -44.299 C 149.91 -43.968 154.483 -41.307 154.126 -41.307"
        style="stroke: rgb(0, 0, 0); fill: none; " stroke-width="3" (click)="showChart()"></path>
      <path
        d="M 95.179 -28.264 C 96.421 -29.126 97.998 -29.227 99.196 -30.059 C 101.808 -31.874 113.008 -39.618 116.413 -38.198 C 120.347 -36.557 122.94 -32.483 127.03 -30.777 C 131.193 -29.041 134.146 -34.212 138.364 -32.453 C 142.248 -30.833 149.737 -26.349 154.146 -26.349"
        style="stroke: rgb(0, 0, 0); fill: none; " stroke-width="3" (click)="showChart()"></path>
      <path
        d="M 96.216 -38.066 C 98.077 -35.873 104.128 -26.349 107.768 -26.349 C 108.147 -26.349 111.214 -28.378 111.915 -28.592 C 119.632 -30.954 126.091 -36.49 132.502 -39.188 C 133.785 -39.728 140.968 -35.56 142.129 -34.95 C 143.844 -34.048 146.82 -33.619 148.053 -32.581 C 150.019 -30.927 153.533 -36.339 153.533 -35.199"
        style="stroke: rgb(0, 0, 0); fill: none; " stroke-width="3" (click)="showChart()"></path>
      <line style="stroke: rgb(0, 0, 0);" x1="91.828" y1="-12.897" x2="151.898" y2="-12.897" stroke-width="3"
        (click)="showChart()"></line>
      <line style="stroke: rgb(0, 0, 0);" x1="91.828" y1="-56.169" x2="91.828" y2="-12.897" stroke-width="3"
        (click)="showChart()"></line>


    </g> -->




    <!--     <g>
      <foreignObject width="950" height="750" *ngIf="chartShow">
        <div id="chartPopup">
          <dx-popup [width]="800" [height]="700" [showTitle]="true" [showCloseButton]="false"
            [closeOnOutsideClick]="false" [dragEnabled]="true" [title]="'Historiek '+definition.scadaElement.legend"
            container=".dx-viewport" [shading]="false" [(visible)]="chartVisible">

            <div id="chart">
              <app-pms-chart [chartData]=chartData></app-pms-chart>
              <hr>
            </div>
            <dxi-toolbar-item toolbar="top">
              <dx-button class="button-info" text="Sluit grafiek" (onClick)="closeChart()">
              </dx-button>
            </dxi-toolbar-item>
            <dxo-position my="center">
            </dxo-position>
          </dx-popup>
        </div>
      </foreignObject>
    </g> -->




  </g>


</svg>