// angular
import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Auth service
import { AuthService } from './auth.service';
// Prime ng
import {MessageService} from 'primeng/api';
//
import {AppComponent} from '../app.component';
import { AppConfig } from '../shared/services';


@Component({
    selector: 'app-force-change-password',
    templateUrl: './app.force-change-password.component.html',
    providers: [MessageService]
})
export class AppForceChangePasswordComponent implements OnInit {

    loading = false;

    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    recoveryCode: string;


    constructor(public app: AppComponent, private authService: AuthService, private router: Router,
                private route: ActivatedRoute,
                private messageService: MessageService,
                public appConfig: AppConfig) {
    }

    ngOnInit(): void {
        console.log("init forcechangepw");
      this.route.paramMap.subscribe(params => {
        this.recoveryCode = params.get('recoveryCode');
      });
    }

    async onSubmit(e) {
        e.preventDefault();
        if (this.newPassword != this.confirmPassword) {
          this.messageService.add({severity:'error', summary: 'Confirm password not equal to new password', detail: "try again"});
          this.newPassword="";
          this.confirmPassword="";
          return; 
        }
        const password = this.password;
        const newPassword = this.newPassword;
        this.loading = true;
        
        const result = await this.authService.changePassword(password, newPassword, this.recoveryCode);
        this.loading = false;

        if (result.isOk) {
          this.messageService.add({severity:'success', summary:'Password change succeeded', detail:'Please login'});
          this.router.navigate(['/']);
        } else {
          this.messageService.add({severity:'error', summary: 'Password change failed', detail: "Contact your administrator"});
        }
        
      }

}
