// angular core
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// components
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
// services
import { BreadcrumbService } from './app.breadcrumb.service';
import { AuthService } from './@auth/auth.service';
// rxjs
import { Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
// primeng api
import { MenuItem } from 'primeng/api';
// store
import { Store, Select } from '@ngxs/store';
import { AddLanguageToSession, LiveDataState, SaiSessionState, ScadaState } from './state';
// user
import { User } from 'src/app/models';
import { AppConfig } from './shared/services';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit, OnDestroy {

    @Select(LiveDataState.getParsedAlarms) parsedAlarms$: Observable<any>;
    private parsedAlarmsSub: Subscription;

    @Select(SaiSessionState.getUser) sessionUser$: Observable<User>;
    private sessionUserSub: Subscription;

    @Select(ScadaState.getAlarms) alarmDefs$: Observable<User>;
    private alarmDefsSub: Subscription;

    subscription: Subscription;

    items: MenuItem[];


    user = { email: 'guest' };

    public numberOfAlarms = 0;
    private alarmDefs: any;

    public alarms = [];

    public languages: any[];
    public selectedLanguage: any;

    constructor(private store: Store, public breadcrumbService: BreadcrumbService, public app: AppComponent, public appMain: AppMainComponent,
        private authService: AuthService, private router: Router, private appConfig: AppConfig,
        private translate: TranslateService) {

    }

    ngOnInit() {
        this.subscription = this.breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });
        this.sessionUserSub = this.sessionUser$
            .pipe(first())
            .subscribe((params: User) => {
                this.user.email = params?.email === undefined ? 'guest' : params.email;
                //  this.setUserContextMenu();
            });
        this.languages = this.appConfig['languages'];

        this.alarmDefsSub= this.alarmDefs$.subscribe((alarmDefs)=>{
           this.alarmDefs=alarmDefs;
        });

        this.parsedAlarmsSub = this.parsedAlarms$.subscribe((parsedAlamrs: any) => {
            this.alarms = [];
            if (parsedAlamrs) {
                const alms = Object.keys(parsedAlamrs);
                alms.forEach(alm => {
                    if (parsedAlamrs[alm].almActive) {
                        this.alarms.push({
                            asset: alm,
                            alarm: this.alarmDefs[parsedAlamrs[alm].oid].text
                        })
                    };
                });
            };
            this.numberOfAlarms = this.alarms.length;


        });

    }

    logOut() {
        if (this.authService.loggedIn) {
            this.authService.logOut().then(() => {
                this.user.email = 'guest';
                this.router.navigate(['/login']);
            });
        }
    }

    onSelectLanguage(event) {
        this.translate.use(this.selectedLanguage['langcode']).subscribe(res => {
            this.store.dispatch(new AddLanguageToSession({ language: this.selectedLanguage['langcode'] }));
        });

    }

    public onBreadcrumbClick(event) {
        // console.log('item clicked: ', event.item);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        };
        if (this.sessionUserSub) {
            this.sessionUserSub.unsubscribe();
        };
        if (this.parsedAlarmsSub) {
            this.parsedAlarmsSub.unsubscribe();
        }
    }


}
