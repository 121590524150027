import { User } from '../../models';

export class AddSaiSession {
  static readonly type = '[SaiSession] Add';

  constructor(public payload: {user: User , startTime: Date, authorityLevel: string}){}

}

export class RemoveSaiSession {
  static readonly type = '[SaiSession] Remove';

  constructor() {}
}

export class AddThemeToSession {
  static readonly type = '[SaiSession] AddTheme';

  constructor(public payload: {theme: string}){}

}

export class AddLanguageToSession {
  static readonly type = '[SaiSession] AddLanguage';

  constructor(public payload: {language: string}){}

}
