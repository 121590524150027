import { Injectable } from '@angular/core';
import * as clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class SaiUtilsService {

  constructor() { }

  getNested(obj, ...args) {
    return args.reduce((obj, level) => obj && obj[level], obj);
  }

  deepClone<T>(value): T {
    return clone<T>(value);
  }
}
