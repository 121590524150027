import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { User } from '../../models';
import { AddSaiSession, RemoveSaiSession, AddThemeToSession, AddLanguageToSession } from './sai-session.actions';

export class SaiSessionModel {
  user: User;
  startTime: Date;
  authorityLevel: string;
  currentTheme: string;
  currentLanguage: string;
}

@State<SaiSessionModel>({
  name: 'saiSession',
  defaults: {
      user: null,
      startTime: null,
      authorityLevel: null,
      currentTheme: null,
      currentLanguage: 'en'

  }
})

@Injectable()
export class SaiSessionState {
  @Selector()
  static getUser(state: SaiSessionModel) {
      return state.user;
  }

  @Selector()
  static getAuthorityLevel(state: SaiSessionModel) {
      return state.authorityLevel;
  }

  @Selector()
  static getCurrentTheme(state: SaiSessionModel) {
    return state.currentTheme;
  }

  
  @Selector()
  static getCurrentLanguage(state: SaiSessionModel) {
    return state.currentLanguage;
  }


  @Action(AddSaiSession)
  add({getState, patchState}: StateContext<SaiSessionModel>, { payload }: AddSaiSession) {
      const state = getState();
      patchState({
          user:  payload.user,
          authorityLevel: payload.authorityLevel,
          startTime: payload.startTime,
      });
  }

  @Action(AddThemeToSession)
  addTheme({getState, patchState}: StateContext<SaiSessionModel>, {payload}: AddThemeToSession) {
    const state = getState();
    patchState({
      currentTheme: payload.theme
    });
  }

  @Action(AddLanguageToSession)
  addLanguate({getState, patchState}: StateContext<SaiSessionModel>, {payload}: AddLanguageToSession) {
    const state = getState();
    patchState({
      currentLanguage: payload.language
    });
  }

  @Action(RemoveSaiSession)
  remove({setState}: StateContext<SaiSessionModel>, { }: RemoveSaiSession) {
       setState(null);
      }
  }


