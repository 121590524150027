// angular
import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Auth service
import { AuthService } from './auth.service';
// Prime ng
import {MessageService} from 'primeng/api';
//
import {AppComponent} from '../app.component';
import { AppConfig } from '../shared/services';


@Component({
    selector: 'app-change-password',
    templateUrl: './app.change-password.component.html',
    providers: [MessageService]
})
export class AppChangePasswordComponent implements OnInit {

    loading = false;

    email: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    recoveryCode: string;

    sregex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;

    pwValOptions={
      'placement': 'top',
      rules: {
          'password': {
              'type': "range",
              'min': 8,
              max: 25
              // No need to pass length
          }
      },
      'shadow': false,
      'offset': 80,   
    }


    constructor(public app: AppComponent, private authService: AuthService, private router: Router,
                private route: ActivatedRoute,
                private messageService: MessageService,
                public appConfig: AppConfig) {
    }

    ngOnInit(): void {
      this.route.paramMap.subscribe(params => {
        this.recoveryCode = params.get('recoveryCode');
      });
    }

    async onSubmit(e) {
        e.preventDefault();
        if (this.newPassword != this.confirmPassword) {
          this.messageService.add({severity:'error', summary: 'Confirm password not equal to new password', detail: "try again"});
          this.newPassword="";
          this.confirmPassword="";
          return; 
        }
        const password = this.password;
        const newPassword = this.newPassword;
        this.loading = true;
        
        const result = await this.authService.changePassword(password, newPassword, this.recoveryCode);
        this.loading = false;

        if (result.isOk) {
          this.messageService.add({severity:'success', summary:'Password change succeeded', detail:'Please login'});
          this.router.navigate(['/']);
        } else {
          this.messageService.add({severity:'error', summary: 'Password change failed', detail: "Contact your administrator"});
        }
        
      }

}
