<svg #door>
    <g class="bg-sv" [attr.transform]="svgTransform">
      <path [ngClass]="{ok : (this.dynamic.variables[this.valRefs[0]].value==0), hidden : (this.dynamic.variables[this.valRefs[0]].value!=0)}" stroke-width="4" fill="none" d="M 261 450 L 4 345 L 4 3 L 261 100 Z"/>
      <path [ngClass]="{alarm : (this.dynamic.variables[this.valRefs[0]].value!=0), hidden : (this.dynamic.variables[this.valRefs[0]].value==0)}" stroke-width="4" fill="none" d="M 50 280 L 4 345 L 4 3 L 50 -40 Z" />
      <path [ngClass]="{alarm : (this.dynamic.variables[this.valRefs[0]].value!=0), hidden : (this.dynamic.variables[this.valRefs[0]].value==0)}" stroke-width="4" fill="none" d="M 261 450 L 330 365 L 330 50 L 261 100 Z" />
      <g class="metadata" >
        <rect id=meta   (click)="onClick()" class="metabg" x="-10" y="-10" width="70" height="70"/>
        <text x="0" y="5" >{{dynamic.variables[valRefs[0]].tag}}</text>
      </g>
    </g>
  
  </svg>
  
  